const firebaseConfig = {
  projectId: "dock-road",
  appId: "1:821352655276:web:4534beca6db19df8953209",
  databaseURL:
    "https://dock-road-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "dock-road.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyB9LOkU6RjvPC1TLVx-xwVBzDX-kfDl3SY",
  authDomain: "dock-road.firebaseapp.com",
  messagingSenderId: "821352655276",
  measurementId: "G-9LJPHE1VS3",
};

export default firebaseConfig;
