<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="relative flex flex-row px-3 py-2 text-green-400 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-green-400 overflow-ellipsis hover:text-green-500 focus:text-green-500 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <div
        class="text-green overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        <ShareIcon :size="18" class="md:hidden inline-block" />
        <div
          class="inline-block ml-2 md:ml-0 md:top-0"
          style="justify-content: center"
        >
          Share
        </div>
      </div>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="filter-container-share shadow-lg"
      :class="filterContainerClass"
    >
      <div
        class="py-5 px-5 dropdownContainer w-52 bg-white rounded-md shadow-lg"
      >
        <ul
          class="listContainerPadding font-normal tracking-wider cursor-pointer"
        >
          <!-- Filter Drop Down Link Content -->
          <li>
            <a
              href=""
              class="hover:text-green-500 focus:text-green-500 top-2"
              @click.prevent="handleLinkClipboardCopy"
            >
              <LinkIcon class="inline-block relative" />
              Link</a
            >
          </li>
          <!-- Filter Drop Down Email Content -->
          <li>
            <a
              class="hover:text-green-500 focus:text-green-500 top-2"
              @click="shareEmail()"
            >
              <EmailIcon class="inline-block relative" />
              Email</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import ShareIcon from "vue-material-design-icons/Share.vue";
import { useClipboard } from "@vueuse/core";
import { APP_TITLE } from "@/constants.js";
import { mapGetters } from "vuex";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
  name: "MainUnitReserveListFilterAspect",
  props: {
    propAspects: {
      default: Array,
    },
    isOutlook: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LinkIcon,
    EmailIcon,
    ShareIcon,
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    dropdownRevealedAt: null,
  }),
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleLinkClipboardCopy() {
      useClipboard().copy(this.getUrl());
      this.addToast(SEVERITY.INFO, { detail: "Link copied to clipboard." });
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    shareEmail: function () {
      const url = this.getUrl();
      const title = APP_TITLE;
      const emailSubject = `I found apartments you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
      window.location = emailLink;
    },
    getUrl() {
      return window.location.origin + this.$route.fullPath;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.selectedAspects = this.propAspects;
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
    filterContainerClass() {
      const classList = ["filter-container-share"];
      if (this.windowWidth <= 750) {
        if (this.globals[0].layoutStyle[0] === "estate") {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              }
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              }
            }
          }
        } else {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              }
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-left");
                } else {
                  classList.push("filter-container-share-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-share-right");
                } else {
                  classList.push("filter-container-share-left");
                }
              }
            }
          }
        }
      } else {
        classList.push("filter-container-share-left");
      }
      return classList.join(" ");
    },
  },
  watch: {
    propAspects: function () {
      this.selectedAspects = this.propAspects;
    },
  },
};
</script>

<style>
.filter-container-share {
  position: absolute;
  transform-origin: top right;
  z-index: 20;
}

.filter-container-share-right {
  right: 0 !important;
}

.filter-container-share-left {
  left: 0 !important;
}

.dropdownContainer {
  width: 18rem;
}

svg.material-design-icon__svg {
  margin-top: -5px !important;
}

.listContainerPadding {
  padding: 17px 17px 0;
}

@media (max-width: 425px) {
  span.material-design-icon {
    margin-top: 0px !important;
  }
}
</style>
