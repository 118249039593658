<template>
  <form class="relative flex flex-col" @submit.prevent="submitForm">
    <div class="flex flex-col md:flex-row -mx-3">
      <div class="w-full md:w-1/2 px-3 mb-5">
        <label for="contact-first-name" class="text-xs font-semibold px-1">
          First name*
        </label>
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <NameIcon class="text-surface-400 text-lg" />
          </div>
          <input
            id="contact-first-name"
            v-model="form.firstName"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': v.form.firstName.$error,
            invisible: !v.form.firstName.$error,
          }"
        >
          Required</span
        >
      </div>
      <div class="w-full md:w-1/2 px-3 b-5">
        <label for="contact-last-name" class="text-xs font-semibold px-1"
          >Last name*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <NameIcon class="text-surface-400 text-lg" />
          </div>
          <input
            id="contact-last-name"
            v-model="form.lastName"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': v.form.lastName.$error,
            invisible: !v.form.lastName.$error,
          }"
        >
          Required</span
        >
      </div>
    </div>

    <div class="flex flex-col md:flex-row -mx-3">
      <div class="w-full md:w-1/2 px-3 mb-5">
        <label for="contact-email" class="text-xs font-semibold px-1"
          >Email*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <EmailIcon class="text-surface-400 text-lg" />
          </div>
          <input
            id="contact-email"
            v-model="form.email"
            type="email"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': v.form.email.$error,
            invisible: !v.form.email.$error,
          }"
        >
          Valid email required</span
        >
      </div>

      <div class="w-full md:w-1/2 px-3 mb-5">
        <label for="" class="lb-icon-text-label">Contact Number</label>
        <PhoneInput
          @onUpdate="handleUpdateE164ContactNumber"
          :e164ContactNumber="form.e164ContactNumber"
        />
        <span
          v-if="v.form.e164ContactNumber.$error"
          class="block w-full text-xs text-red-600"
        >
          Please enter a valid contact number</span
        >
      </div>
    </div>

    <div v-if="unitName">
      <p>
        Would you like to contact us about {{ unitName }}?
        <input
          class="ml-2 cursor-pointer bg-primary"
          type="checkbox"
          v-model="checked"
          id="checkbox"
        />
      </p>
    </div>

    <div class="flex -mx-3">
      <div class="w-full px-3 mb-8">
        <label for="contact-message" class="text-xs font-semibold px-1"
          >Message*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <MessageIcon class="text-surface-400 text-lg" />
          </div>
          <textarea
            id="contact-message"
            v-model="form.message"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 h-32 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': v.form.message.$error,
            invisible: !v.form.message.$error,
          }"
        >
          At least 5 letters required</span
        >
      </div>
    </div>

    <div class="flex -mx-3 justify-end">
      <div class="px-3 mb-2">
        <input
          :disabled="formError"
          class="block w-full bg-primary hover:bg-primary-600 focus:bg-primary text-white uppercase rounded-lg px-3 py-3 font-semibold"
          type="submit"
          value="Send"
        />

        <p
          class="absolute top-0 left-0 z-50 flex flex-col justify-center items-center h-full w-full bg-white duration-150"
          v-if="successMessage"
        >
          <MessageSentIcon :size="80" class="text-green-500" />
          Thank you for contacting us. We will be in touch shortly.
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import firebase from "firebase/compat/app";
import { messagesCollection } from "@/firebaseCompat.js";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import EmailIcon from "vue-material-design-icons/Email.vue";
import MessageIcon from "vue-material-design-icons/MessageText.vue";
import NameIcon from "vue-material-design-icons/Account.vue";
import MessageSentIcon from "vue-material-design-icons/CheckAll.vue";
import { mapGetters } from "vuex";
import { isNil } from "lodash-es";
import PhoneInput from "@/components/shared/PhoneInput.vue";

export default {
  setup() {
    const v = useVuelidate();
    return { v };
  },
  name: "MainNavContactButtonMessageTab",
  props: {
    unitName: {
      type: String,
    },
    unitErf: {
      type: String,
    },
  },
  components: {
    EmailIcon,
    MessageIcon,
    NameIcon,
    MessageSentIcon,
    PhoneInput,
  },
  data: () => ({
    successMessage: false,
    checked: true,
    formError: false,
    form: {
      firstName: null,
      lastName: null,
      email: null,
      e164ContactNumber: null,
      message: null,
    },
  }),
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      message: { required, minLength: minLength(5) },
      e164ContactNumber: { required },
    },
  },
  methods: {
    handleUpdateE164ContactNumber(e164ContactNumber) {
      this.form.e164ContactNumber = e164ContactNumber;
    },
    submitForm() {
      this.v.form.$touch();
      if (this.v.form.$error) {
        return;
      }

      const timeStamp = firebase.firestore.Timestamp.now();

      const messageData = {
        form: "Navigation Message Tab Form",
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        e164ContactNumber: this.form.e164ContactNumber,
        unitName: this.unitName
          ? this.checked === true
            ? this.unitName
            : "not applicable"
          : "not applicable",
        message: this.form.message,
        timestamp: timeStamp,
      };

      messagesCollection.add(messageData).then(() => {
        this.successMessage = true;
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
  },
  created() {
    const user = this.user;

    if (!isNil(user) && user.loggedIn) {
      if (!isNil(user) && !isNil(user.profile)) {
        this.form.firstName = user.profile.firstName;
        this.form.lastName = user.profile.lastName;
        this.form.email = user.profile.email;
        this.form.e164ContactNumber = user.profile.e164ContactNumber;
      }
    }
  },
};
</script>

<style>
#checkbox {
  color: var(--button-background-color);
  outline-color: var(--button-background-color);
  outline-style: outset;
}

#checkbox:focus {
  --checkbox-opacity: 0.5;
  outline-color: var(--button-background-color) var(--checkbox-opacity);
  outline-style: outset;
}

#contact-first-name:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-last-name:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-email:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-phone:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-message:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

.input-tel__label {
  color: var(--boarder-color) !important;
}

.input-tel__input {
  border-width: 2px !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.input-tel.is-valid .input-tel__label[data-v-e59be3b4] {
  color: #424542 !important;
}

.input-tel__input:focus,
.input-tel__input:hover,
::before,
::after,
.country-selector__input:focus,
.country-selector__input:hover,
.input-tel__input:active {
  border-width: 2px;
  --tw-border-opacity: 1 !important;
  --tw-ring-color: var(--boarder-color) var(--tw-border-opacity) !important;
  border-color: var(--boarder-color) !important;
  box-shadow: var(--boarder-color) var(--tw-border-opacity) !important;

  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: #fff !important;
  --tw-ring-color: var(--boarder-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  border-color: var(--boarder-color) !important;
}

.country-selector__list__item.selected {
  background-color: var(--boarder-color) !important;
}

.country-selector__input {
  border-width: 2px !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.country-selector__input:focus {
  border-width: 2px;
  /*outline: 2px solid #2563eb !important;*/
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}

.country-selector__label {
  color: #424542 !important;
}

.phone-container {
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.phone-container:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}
</style>
