import { useToast } from "primevue/usetoast";
import { ERRORS } from "@/constants/index.js";

const EXTENDED_LIFE = 6000;
const SHORTENED_LIFE = 3000;

export const SEVERITY = {
  ERROR: {
    severity: "error",
    summary: ERRORS.DEFAULT,
    detail: ERRORS.REFRESH,
    life: EXTENDED_LIFE,
  },
  WARNING: {
    severity: "warn",
    summary: "Warning",
    life: EXTENDED_LIFE,
  },
  SUCCESS: {
    severity: "success",
    summary: "Success",
    life: SHORTENED_LIFE,
  },
  INFO: {
    severity: "info",
    summary: "Info",
    life: SHORTENED_LIFE,
  },
};

/**
 * Create a new toast message with a style based on the severity and content based on the config.
 *
 * @returns {function} A function that accepts a severity & configuration object and displays a toast.
 * @param {Object} severity - The toast severity configuration.
 * @param {Object} config - Toast configuration (e.g. the details of the message)
 */
export const usePrimeToast = () => {
  const toast = useToast();
  return (severity, config) => {
    toast.add({
      ...severity,
      ...config,
    });
  };
};
