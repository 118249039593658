<template>
  <div>
    <!-- Global Menu Content -->
    <div v-if="windowWidth > 750" class="global-Menu-Content">
      <span class="relative inline-flex rounded-md shadow-sm">
        <!-- Table & computer -->
        <button
          @click="toggleDropdown"
          type="button"
          class="inline-flex items-center px-4 py-2 text-white text-sm font-semibold uppercase tracking-wider transition ease-in-out duration-150 rounded hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
        >
          <ProfileIcon class="text-navbar-text" :size="30" />
        </button>
      </span>

      <div v-if="user && user.loggedIn">
        <!-- Drop Down Menu Content -->
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul
              v-click-outside="toggleDropdown"
              class="text-center font-semibold"
            >
              <!-- Global Discount Content -->

              <li
                v-if="
                  globals[0].allowDiscount &&
                  user.loggedIn &&
                  !globals[0].allowDiscountNoSurvey
                "
              >
                <MainNavBarProfileDropdownDiscount
                  class="mb-1 p-1"
                  :close-menu="toggleDropdown"
                />
              </li>

              <!-- Shortlist Content -->
              <li class="mb-1">
                <span v-if="globals[0].layoutStyle[0] === 'estate'">
                  <MainNavBarProfileDropdownShortlistEstate
                    :msTillLaunchTime="msTillLaunchTime"
                    :close-menu="toggleDropdown"
                    :user="user"
                    :settings="globals[0]"
                    @update:tooltip="updateTooltip($event)"
                  />
                </span>
                <span v-else>
                  <MainNavBarProfileDropdownShortlist
                    :msTillLaunchTime="msTillLaunchTime"
                    :close-menu="toggleDropdown"
                    :user="user"
                    :settings="globals[0]"
                    @update:tooltip="updateTooltip($event)"
                  />
                </span>
              </li>

              <!-- User Profile Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <router-link
                  :msTillLaunchTime="msTillLaunchTime"
                  :to="{ name: 'profile' }"
                  @click="displayDropdown = false"
                  class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  My Profile
                </router-link>
              </li>

              <!-- Admin Btn Content -->
              <li
                v-on:click="toggleDropdown"
                class="mb-1"
                v-if="
                  user &&
                  user.loggedIn &&
                  userRoleStatuses.includes(user.profile.role)
                "
              >
                <router-link
                  :msTillLaunchTime="msTillLaunchTime"
                  :to="{ name: 'admin' }"
                  @click="displayDropdown = false"
                  class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  Admin Dashboard
                </router-link>
              </li>

              <!-- Menu Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  @click="signOut"
                >
                  Sign Out</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul
              v-click-outside="toggleDropdown"
              class="text-center font-semibold"
            >
              <!-- Auth Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <router-link
                  class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  :to="{ name: 'login' }"
                >
                  Login / Sign Up
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainNavBarProfileDropdownDiscount from "./MainNavBarProfileDropdownDiscount.vue";
import MainNavBarProfileDropdownShortlist from "./MainNavBarProfileDropdownShortlist.vue";
import MainNavBarProfileDropdownShortlistEstate from "./MainNavBarProfileDropdownShortlistEstate.vue";
import ProfileIcon from "vue-material-design-icons/AccountCircle.vue";
import { mapState } from "pinia";
import { useUserStore } from "@/state/index.js";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "MainNavBarDownloadsDropdown",
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MainNavBarProfileDropdownDiscount,
    MainNavBarProfileDropdownShortlist,
    MainNavBarProfileDropdownShortlistEstate,
    ProfileIcon,
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    componentLoading: false,
    open: false,
    userRoleStatuses: [
      "admin",
      "superadmin",
      "agent-principal",
      "agent",
      "admin-viewonly",
    ],
    dropdownRevealedAt: null,
  }),
  computed: {
    ...mapState(useUserStore, ["logOut"]),
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
  },
  methods: {
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    async signOut() {
      try {
        await this.logOut();
        if (!this.globals[0].dataCollection) return;

        this.$router.replace({
          name: "login",
        });
      } catch ({ message: detail }) {
        this.addToast(SEVERITY.ERROR, { detail });
      }
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style>
.menu-dropdown-container {
  position: absolute;
  z-index: 40;
  right: 0;
  margin-top: 0.5rem;
  transform-origin: top right;
}

.menu-dropdown-content {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.375rem;
  width: 16rem;
  overflow-y: auto;
}

.global-Menu-Content {
  position: relative;
  width: 100%;
}
</style>
