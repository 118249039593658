import { STATUS } from "../status.js";
import { profilesCollection } from "../../firebaseCompat.js";
import firebase from "firebase/compat/app";
import * as Sentry from "@sentry/vue";
import { FIREBASE_ERROR_CODES, ERRORS } from "@/constants/index.js";

export const actions = {
  async logIn(email, password) {
    try {
      this.status = STATUS.PENDING;

      const {
        user: { uid, email: _email },
      } = await firebase.auth().signInWithEmailAndPassword(email, password);

      const doc = await profilesCollection.doc(uid).get();
      const user = doc.data();
      if (!doc.exists || user === undefined) {
        await firebase.auth().currentUser.delete();
        throw new Error(ERRORS.PROFILE_MALFORMED);
      }

      this.listenToUser(uid, _email);
    } catch (error) {
      this.status = STATUS.REJECTED;
      if (
        error.code === FIREBASE_ERROR_CODES.USER_NOT_FOUND ||
        error.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD ||
        error.code === FIREBASE_ERROR_CODES.INVALID_CREDENTIAL
      ) {
        throw new Error(ERRORS.INVALID_LOGIN_DETAILS);
      }

      if (error.code === FIREBASE_ERROR_CODES.TOO_MANY_REQUESTS) {
        throw new Error(ERRORS.ACCOUNT_ACCESS_DISABLED);
      }

      throw error;
    }
  },
  async listenToUser(id, email) {
    try {
      if (this.status !== STATUS.PENDING) this.status = STATUS.PENDING;

      const docRef = profilesCollection.doc(id);

      if (this.user === undefined) {
        const doc = await docRef.get();
        const user = doc.data();

        if (!doc.exists || user === undefined) {
          firebase.auth().signOut();
          throw new Error(ERRORS.PROFILE_MALFORMED);
        }

        Sentry.setUser({
          id,
          email,
        });

        this.uid = id;
        this.user = user;
      }

      this.stopListening = docRef.onSnapshot((doc) => {
        const user = doc.data();

        if (!doc.exists || user === undefined) {
          throw new Error(ERRORS.REFRESH);
        }

        this.user = user;
      });

      this.status = STATUS.LISTENING;
    } catch (error) {
      this.status = STATUS.REJECTED;
      throw error;
    }
  },
  async updateUser({ email: _, ...updatedUser }) {
    try {
      if (this.status !== STATUS.LISTENING) {
        this.status = STATUS.PENDING;
      }

      const docRef = profilesCollection.doc(this.uid);
      const modified = firebase.firestore.FieldValue.serverTimestamp();
      await docRef.update({
        ...updatedUser,
        modified,
      });

      if (this.status === STATUS.LISTENING) return;

      const doc = await docRef.get();
      const user = doc.data();
      if (!doc.exists || user === undefined) {
        throw new Error(ERRORS.REFRESH);
      }

      this.user = user;
      this.status = STATUS.RESOLVED;
    } catch (error) {
      this.status = STATUS.REJECTED;
      throw error;
    }
  },
  async updateUserEmail(email) {
    try {
      if (this.status !== STATUS.LISTENING) {
        this.status = STATUS.PENDING;
      }

      await firebase.auth().currentUser.updateEmail(email);

      const modified = firebase.firestore.FieldValue.serverTimestamp();
      await profilesCollection.doc(this.uid).update({
        email,
        modified,
      });

      if (this.status === STATUS.LISTENING) return;

      this.user.email = email;
      this.user.modified = modified;
      this.status = STATUS.RESOLVED;
    } catch (error) {
      this.status = STATUS.REJECTED;
      if (error.code === FIREBASE_ERROR_CODES.REQUIRES_RECENT_LOGIN) {
        throw new Error(ERRORS.SENSITIVE_OPERATION);
      }
      throw error;
    }
  },
  async logOut() {
    try {
      if (this.status !== STATUS.LISTENING) {
        this.status = STATUS.PENDING;
      }

      await firebase.auth().signOut();
      Sentry.setUser(null);
      this.resetUserStore();

      this.status = STATUS.IDLE;
    } catch (error) {
      this.status = STATUS.REJECTED;
      throw error;
    }
  },
  resetUserStore() {
    if (this.stopListening !== undefined) {
      this.stopListening();
    }

    this.$reset();
  },
};
