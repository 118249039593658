import button from "./button";
import calendar from "./calendar";
import checkbox from "./checkbox";
import datatable from "./datatable";
import dialog from "./dialog";
import divider from "./divider";
import dropdown from "./dropdown";
import fileupload from "./fileupload";
import global from "./global.js";
import iconfield from "./iconfield";
import image from "./image";
import inputswitch from "./inputswitch";
import inputtext from "./inputtext";
import multiselect from "./multiselect";
import paginator from "./paginator";
import panel from "./panel";
import progressbar from "./progressbar";
import ripple from "./ripple";
import selectbutton from "./selectbutton";
import skeleton from "./skeleton";
import tag from "./tag";
import textarea from "./textarea";
import toast from "./toast";
import tooltip from "./tooltip";
import progressspinner from "./progressspinner";
import confirmdialog from "./confirmdialog/index.js";
import slider from "./slider";
import metergroup from "./metergroup";

export default {
  global,
  directives: {
    ripple,
    tooltip,
  },
  button,
  calendar,
  checkbox,
  datatable,
  dialog,
  divider,
  dropdown,
  fileupload,
  iconfield,
  image,
  inputswitch,
  inputtext,
  multiselect,
  paginator,
  panel,
  progressbar,
  selectbutton,
  skeleton,
  tag,
  textarea,
  toast,
  toolbar,
  progressspinner,
  confirmdialog,
  slider,
  metergroup,
};
