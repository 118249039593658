// myMixin.js

export const sort = {
  data() {
    return {};
  },
  methods: {
    processedData: function (sortField) {
      if (this.sortField === "name") {
        // Find the index of the "&" symbol
        let indexOfAmpersand = sortField.indexOf("&");

        // If "&" is found, extract the substring before it; otherwise, return the original string
        return indexOfAmpersand !== -1
          ? sortField.substring(0, indexOfAmpersand).replace(/a/g, "")
          : sortField;
      } else {
        return sortField;
      }
    },
    extractPartsSort: function (sortField) {
      if (
        this.sortField !== "type" &&
        this.sortField !== "aspect" &&
        this.globals[0].layoutStyle[0] !== "plot size"
      ) {
        const regex = /[a-zA-Z\d]+/g;
        const matches = sortField.match(regex);
        return matches
          ? [
              /\d/.test(matches[0])
                ? parseInt(matches[0])
                : matches[0] === "ground"
                  ? 0
                  : matches[0],
              matches[1],
            ]
          : [sortField, NaN];
      }
      if (this.globals[0].layoutStyle[0] === "plot size") {
        const regex = /[a-zA-Z\d]+/g;
        const matches = sortField.match(regex);
        return matches ? [matches[0], matches[1]] : [sortField, NaN];
      } else {
        return [sortField, null];
      }
    },
    extractPartsSortEach: function (sortField) {
      const regex = /([a-zA-Z]+)|(\d+)/g;
      const data = sortField.toString();
      const matches = data.match(regex) || [];

      return matches.map((match) => (isNaN(match) ? match : parseInt(match)));
    },
    makeArrayLetters: function (items) {
      return items && isNaN(items) && items.match(/[a-zA-Z]+/g) ? items : null;
    },
    makeArrayNumbers: function (items) {
      if (!isNaN(items)) {
        return items;
      } else {
        const digits = items && !isNaN(items) && items.match(/\d+/g);
        return digits ? digits : null;
      }
    },
    removeLeadingZeros: function (name) {
      if (this.globals[0].removeLeadingZeros) {
        // Remove leading zeros from numbers
        const numADate =
          name && isNaN(name) && name.match(/[a-zA-Z]+/g)
            ? name.replace(/^0+/, "")
            : name;

        // Compare numbers
        const intADate = parseInt(numADate);

        if (!isNaN(intADate)) {
          return intADate;
        } else {
          // If numbers are not available, fallback to string comparison
          return numADate;
        }
      } else {
        return name;
      }
    },
  },
  computed: {
    mixinFilteredUnits: function () {
      let units = this.units.filter((item) => item.price);

      return units.sort((a, b) => {
        let modifier = this.sortDirection === "desc" ? -1 : 1;
        const sortFieldA = a[this.sortField]
          ? a[this.sortField].toString().toLowerCase().trim()
          : a[this.sortField].toString().trim()
            ? a[this.sortField].toString().trim()
            : "";
        const sortFieldB = b[this.sortField]
          ? b[this.sortField].toString().toLowerCase().trim()
          : b[this.sortField].toString().trim()
            ? b[this.sortField].toString().trim()
            : "";

        let processedA = this.processedData(sortFieldA);
        let processedB = this.processedData(sortFieldB);

        // Extract letters and numbers from the names
        const [lettersADate, numberADate] = this.extractPartsSort(processedA);
        const [lettersBData, numbersBData] = this.extractPartsSort(processedB);

        if (this.sortField !== "type" && this.sortField !== "aspect") {
          // Extract letters and numbers from the names
          let lettersA, numbersA;
          if (lettersADate !== undefined) {
            [lettersA, numbersA] = this.extractPartsSortEach(lettersADate);
          } else {
            lettersA = [];
            numbersA = [];
          }

          let lettersASecond, numbersASecond;
          if (numberADate !== undefined) {
            [lettersASecond, numbersASecond] =
              this.extractPartsSortEach(numberADate);
          } else {
            lettersASecond = [];
            numbersASecond = [];
          }

          let lettersB, numbersB;
          if (lettersBData !== undefined) {
            [lettersB, numbersB] = this.extractPartsSortEach(lettersBData);
          } else {
            lettersB = [];
            numbersB = [];
          }

          let lettersBSecond, numbersBSecond;
          if (numbersBData !== undefined) {
            [lettersBSecond, numbersBSecond] =
              this.extractPartsSortEach(numbersBData);
          } else {
            lettersBSecond = [];
            numbersBSecond = [];
          }

          const letterA = [
            this.makeArrayLetters(lettersA),
            this.makeArrayLetters(numbersA),
            this.makeArrayLetters(lettersASecond),
            this.makeArrayLetters(numbersASecond),
          ];

          const letterB = [
            this.makeArrayLetters(lettersB),
            this.makeArrayLetters(numbersB),
            this.makeArrayLetters(lettersBSecond),
            this.makeArrayLetters(numbersBSecond),
          ];

          const numberA = [
            this.makeArrayNumbers(lettersA),
            this.makeArrayNumbers(numbersA),
            this.makeArrayNumbers(lettersASecond),
            this.makeArrayNumbers(numbersASecond),
          ];

          const numberB = [
            this.makeArrayNumbers(lettersB),
            this.makeArrayNumbers(numbersB),
            this.makeArrayNumbers(lettersBSecond),
            this.makeArrayNumbers(numbersBSecond),
          ];

          for (let x = 0; x < letterA.length; x++) {
            if (
              this.globals[0].layoutStyle[0] === "estate" &&
              (x === 0 || x === 2) &&
              letterA[x] !== letterB[x] &&
              (letterA[x] !== null || letterB[x] !== null)
            ) {
              // If numbers are not equal, return the comparison result for numbers
              return letterA[x].localeCompare(letterB[x]) * modifier;
            } else if (
              this.globals[0].layoutStyle[0] !== "estate" &&
              x === 0 &&
              x === 2 &&
              letterA[x] !== letterB[x] &&
              letterA[x] !== null &&
              letterB[x] !== null
            ) {
              // If numbers are not equal, return the comparison result for numbers
              return letterA[x].localeCompare(letterB[x]) * modifier;
            }
          }

          // If letters are the same, compare numbers
          for (let i = 0; i < numberA.length; i++) {
            if (
              numberA[i] !== numberB[i] &&
              numberA[i] !== null &&
              numberB[i] !== null
            ) {
              // If numbers are not equal, return the comparison result for numbers
              return (numberA[i] - numberB[i]) * modifier;
            }
          }

          // If numbers are the not same, compare letters
          for (let j = 0; j < letterA.length; j++) {
            if (
              (letterA[j] !== letterB[j] || letterA[j] !== letterB[j]) &&
              letterA[j] !== null &&
              letterB[j] !== null
            ) {
              return letterA[j].localeCompare(letterB[j]) * modifier;
            }
          }

          // If all numbers and letters are equal, return 0
          return 0;
        } else if (this.sortField === "type" || this.sortField === "aspect") {
          return lettersADate.localeCompare(lettersBData) * modifier;
        } else {
          const regex = /[a-zA-Z\d]+/g;

          if (
            lettersADate !== lettersBData &&
            regex.test(lettersADate) &&
            regex.test(lettersBData)
          ) {
            return lettersADate.localeCompare(lettersBData) * modifier;
          }

          if (!numberADate || !numbersBData) {
            // If letters are the same, compare numbers
            return (lettersADate - lettersBData) * modifier;
          } else {
            // If letters are the same, compare numbers
            return (numberADate - numbersBData) * modifier;
          }
        }
      });
    },
  },
};
