import { ref, watch } from "vue";
import { useDocument } from "vuefire";
import { doc } from "firebase/firestore";
import { firestore } from "@/firebase";
import { firestoreStore, adjustShade } from "@/utils";

const hex = (code) => `#${code}`;

const unitPinConfigurationAvailable = ref(undefined);
const unitPinConfigurationUnavailable = ref(undefined);

export const useUnitPinConfiguration = () => {
  const { data: configuration } = useDocument(
    doc(firestore, "settings", "plan"),
  );
  const {
    state: { theme },
  } = firestoreStore();

  watch(
    [configuration, theme],
    ([_configuration, _theme]) => {
      if (!_theme) return;

      const size = _configuration?.size ?? 100;

      if (!_configuration || _configuration.useThemeColours) {
        unitPinConfigurationAvailable.value = {
          size,
          colours: {
            border: hex(_theme.colours.primary),
            background: hex(_theme.colours.primary),
            textName: "#ffffff",
            textPrice: "#ffffff",
          },
        };
        unitPinConfigurationUnavailable.value = {
          size,
          colours: {
            border: adjustShade(_theme.colours.surface, -40),
            background: adjustShade(_theme.colours.surface, -40),
            textName: adjustShade(_theme.colours.surface, -10),
            textPrice: adjustShade(_theme.colours.surface, -10),
          },
        };
      } else {
        unitPinConfigurationAvailable.value = {
          size,
          colours: {
            border: hex(_configuration.colours.availableUnit.border),
            background: hex(_configuration.colours.availableUnit.background),
            textName: hex(_configuration.colours.availableUnit.name),
            textPrice: hex(_configuration.colours.availableUnit.price),
          },
        };
        unitPinConfigurationUnavailable.value = {
          size,
          colours: {
            border: hex(_configuration.colours.unavailableUnit.border),
            background: hex(_configuration.colours.unavailableUnit.background),
            textName: hex(_configuration.colours.unavailableUnit.name),
            textPrice: hex(_configuration.colours.unavailableUnit.price),
          },
        };
      }
    },
    { immediate: true },
  );

  return {
    unitPinConfigurationAvailable,
    unitPinConfigurationUnavailable,
  };
};
