const permissions = {
  units: {
    add: ["superadmin"],
    update: ["agent-principal", "admin", "superadmin"],
    delete: ["admin", "superadmin"],
    import: ["superadmin"],
  },
  deals: {
    view: ["admin", "superadmin"],
  },
  plans: {
    view: ["superadmin"],
  },
  users: {
    view: ["admin", "superadmin"],
    "change-user-role": ["admin", "superadmin"],
  },
  agents: {
    view: ["admin", "superadmin"],
    add: ["admin", "superadmin", "agent-principal"],
    update: ["agent-principal", "admin", "superadmin"],
    delete: ["admin", "superadmin", "agent-principal"],
  },
  attendants: {
    view: ["admin", "superadmin"],
    add: ["admin", "superadmin", "agent-principal"],
    update: ["agent-principal", "admin", "superadmin"],
    delete: ["admin", "superadmin", "agent-principal"],
  },
  integrations: {
    view: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
  },
  theme: {
    view: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
  },
  account: {
    view: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
  },
  survey: {
    view: ["admin", "superadmin"],
    add: ["admin", "superadmin", "agent-principal"],
    update: ["agent-principal", "admin", "superadmin"],
    delete: ["admin", "superadmin", "agent-principal"],
  },
  ctaCards: {
    view: ["superadmin", "admin"],
    add: ["superadmin", "admin"],
    update: ["superadmin", "admin"],
    delete: ["superadmin", "admin"],
  },
  "email-templates": {
    view: ["admin", "superadmin"],
    add: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
    delete: ["admin", "superadmin"],
  },
  data: {
    export: ["admin", "superadmin", "admin-viewonly"],
  },
  settings: {
    view: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
  },
  downloads: {
    view: ["admin", "superadmin", "admin-viewonly"],
    add: ["admin", "superadmin"],
    update: ["admin", "superadmin"],
    delete: ["admin", "superadmin"],
  },
  profiles: {
    view: ["superadmin"],
    update: ["superadmin"],
  },
};

// TODO: this seems permitted-by-default, should be more secure to invert it
export function hasPermission(area, action, role) {
  if (!permissions[area]) {
    return true;
  }

  if (permissions[area] && !permissions[area][action]) {
    return true;
  }

  return permissions[area] && permissions[area][action].includes(role);
}
