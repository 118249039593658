<template>
  <div>
    <button
      class="mb-1 block w-full font-semibold rounded hover:text-white hover:bg-surface-600 cursor-pointer"
      v-on:click="showDialog"
    >
      <span>Contact Us</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "MainNavBarContactButton",
  methods: {
    showDialog() {
      this.emitter.emit("update:unit-contact-data", { open: true });
    },
  },
};
</script>
