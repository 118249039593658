import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/database";
import firebaseSdkConfig from "../firebaseSdkConfig.js";

const firebaseConfig = firebaseSdkConfig;

firebase.initializeApp(firebaseConfig);
firebase.firestore.setLogLevel("error");

const db = firebase.firestore();
const unitsCollection = db.collection("units");
const surveyCollection = db.collection("survey");
const additionalExtrasCollection = db.collection("additionalExtras");
const downloadsCollection = db.collection("downloads");
const profilesCollection = db.collection("profiles");
const settingsCollection = db.collection("settings");
const messagesCollection = db.collection("messages");
const agentsCollection = db.collection("agents");
const attendantsCollection = db.collection("attendants");
const dealsCollection = db.collection("deals");
const paystackAuditCollection = db.collection("paystackAudit");
const emailTemplateCollection = db.collection("emailTemplates");
const noAvailableCollection = db.collection("noAvailable");
const secretsCollection = db.collection("secrets");

export {
  db,
  surveyCollection,
  additionalExtrasCollection,
  unitsCollection,
  downloadsCollection,
  profilesCollection,
  settingsCollection,
  messagesCollection,
  agentsCollection,
  attendantsCollection,
  dealsCollection,
  emailTemplateCollection,
  paystackAuditCollection,
  noAvailableCollection,
  secretsCollection,
};
