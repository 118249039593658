<template>
  <div class="relative overflow-hidden">
    <img class="w-full" :src="headerImage" v-if="headerImage && !loading" />
    <img
      :alt="title"
      class="w-full"
      src="~@/assets/images/Header.png"
      v-if="!headerImage && !loading"
    />
    <button class="absolute top-10 w-full text-center"></button>
  </div>
</template>

<script>
import { settingsCollection } from "@/firebaseCompat.js";
import { APP_TITLE } from "@/constants.js";

export default {
  name: "PageBanner",
  data() {
    return {
      title: APP_TITLE,
      headerImage: null,
      loading: true,
    };
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot(
      {
        // Listen for document metadata changes
        includeMetadataChanges: true,
      },
      (doc) => {
        this.headerImage = doc.data().headerImage;
        this.loading = false;
      },
    );
  },
};
</script>
