<template>
  <div>
    <div
      v-if="unitDialogData.open"
      @click="toggleModal()"
      class="lb-dialog-background"
    ></div>
    <div
      v-if="unitDialogData.open"
      class="fixed z-[9999] top-8 left-1/2 w-11/12 lg:w-11/12 transform -translate-x-1/2 rounded"
      style="max-height: 100%; overflow-x: hidden; padding-bottom: 50px"
    >
      <button class="lb-dialog-close-btn-x" v-on:click="closeModal">×</button>
      <div>
        <!-- wrapper -->
        <div class="h-full overflow-y-scroll overflow-x-hidden">
          <div class="flex flex-col lg:flex-row">
            <!-- carousel -->
            <div
              class="relative bg-white w-full lg:w-2/3 order-first lg:order-last outline-none"
              style="min-height: 200px"
            >
              <MainCarousel
                :slides="unitDialogData.data.images"
                :global="globals[0]"
              />
            </div>

            <!-- data -->
            <div
              class="flex flex-col w-full lg:w-1/3 pt-5 pb-5 lg:pt-10 px-5 bg-surface-200 z-40"
            >
              <!-- specs -->
              <div class="w-full z-40">
                <span
                  v-if="unitDialogData.data.isGuaranteedRental"
                  class="has-tooltip guaranteed-rental-container z-40"
                >
                  GR
                  <span class="tooltip -bottom-5 left-10 font-normal z-40">
                    Guaranteed Rental
                  </span>
                </span>
                <p class="headerCss text-2xl font-semibold tracking-widest">
                  {{ title }}
                </p>
                <p class="mt-2 text-xl">
                  {{
                    globals[0].isUnitNameLabel
                      ? globals[0].unitNameLabel !== null
                        ? `${globals[0].unitNameLabel} ${cutAfterHyphen(
                            unitDialogData.data.name,
                          )}`
                        : `${cutAfterHyphen(unitDialogData.data.name)}`
                      : `${cutAfterHyphen(unitDialogData.data.name)}`
                  }}
                </p>

                <p class="text-xl">
                  {{ unitDialogData.data.floor }}
                </p>

                <p v-if="globals[0].isErfNumber" class="mb-2 text-xl">
                  Erf {{ unitDialogData.data.standNumber }}
                </p>

                <p v-if="globals[0].isAspect" class="mb-2 text-xl">
                  {{ unitDialogData.data.aspect }}
                </p>

                <div v-if="globals[0].isPrice">
                  <UnitPrice
                    :unit="unitDialogData.data"
                    :settings="globals[0]"
                    :normalPrice="false"
                    :bigPrice="true"
                    :show="true"
                    :tableStyle="false"
                  />
                </div>

                <!-- pills -->
                <div class="flex justify-start flex-wrap mt-2">
                  <span
                    v-if="
                      unitDialogData.data.bedrooms &&
                      unitDialogData.data.bedrooms > 0 &&
                      globals[0].isBedroomsCard
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <BedroomIcon class="mr-1" />
                    {{ unitDialogData.data.bedrooms }}
                    <span class="tooltip -top-10 left-0">Bedrooms</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.internalAreaEstate &&
                      unitDialogData.data.internalAreaEstate > 0 &&
                      globals[0].isInternalAreaEstate
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <TotalIntAreaIcon class="mr-1" />
                    {{ unitDialogData.data.internalAreaEstate }}m&sup2;
                    <span class="tooltip -top-10 left-0">Internal Area</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.bathrooms &&
                      unitDialogData.data.bathrooms > 0 &&
                      globals[0].isBathroomsCard
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <BathroomIcon class="mr-1" />
                    {{ unitDialogData.data.bathrooms }}
                    <span class="tooltip -top-10 left-0">Bathrooms</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.balcony &&
                      unitDialogData.data.balcony > 0 &&
                      globals[0].isBalcony
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <BalconyIcon />
                    {{ unitDialogData.data.balcony }}m&sup2;
                    <span class="tooltip -top-10 left-0">Balcony</span>
                  </span>

                  <div
                    v-if="
                      unitDialogData.data.coveredPatio &&
                      unitDialogData.data.coveredPatio > 0 &&
                      globals[0].isCoveredPatio
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <CoverPatioIcon />
                    {{ unitDialogData.data.coveredPatio }}m&sup2;
                    <span class="tooltip -top-10 left-0">Patio</span>
                  </div>

                  <span
                    v-if="
                      unitDialogData.data.garden &&
                      unitDialogData.data.garden > 0 &&
                      globals[0].isGarden
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <FlowerOutlineIcon />
                    {{ unitDialogData.data.garden }}m&sup2;
                    <span class="tooltip -top-10 left-0">Garden</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.parking &&
                      unitDialogData.data.parking > 0 &&
                      globals[0].isParkingCard
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <CarIcon class="mr-1" />
                    {{ unitDialogData.data.parking }}
                    <span class="tooltip -top-10 left-0">Parking Bays</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.garages &&
                      unitDialogData.data.garages > 0
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <GarageIcon class="mr-1" />
                    <span class="flex justify-center">
                      {{ unitDialogData.data.garages }}
                      <div v-html="globals[0].isGarageUnit"></div>
                    </span>
                    <span class="tooltip -top-10 left-0">{{
                      globals[0].isGarageUnit !== ""
                        ? "Garage"
                        : unitDialogData.data.garages === 1
                          ? "Garage"
                          : "Garages"
                    }}</span>
                  </span>

                  <div
                    v-if="
                      unitDialogData.data.totalAreaEstate &&
                      unitDialogData.data.totalAreaEstate > 0 &&
                      globals[0].isTotalAreaEstate
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <TotalAreaIcon />
                    {{ unitDialogData.data.totalAreaEstate }}m&sup2;
                    <span class="tooltip -top-10 left-0">Total Area</span>
                  </div>

                  <span
                    v-if="
                      unitDialogData.data.storeroom &&
                      unitDialogData.data.storeroom > 0 &&
                      globals[0].isStoreroom
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <StoreIcon class="mr-1" />
                    {{ unitDialogData.data.storeroom }}
                    <span class="tooltip -top-10 left-0">Storeroom</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.internalArea &&
                      unitDialogData.data.internalArea > 0 &&
                      globals[0].isInternalAreaCard
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <HomeIcon class="mr-1" />
                    {{ unitDialogData.data.internalArea }}m&sup2;
                    <span class="tooltip -top-10 left-0">Home Size</span>
                  </span>

                  <span
                    v-if="
                      unitDialogData.data.externalArea &&
                      unitDialogData.data.externalArea > 0 &&
                      globals[0].isExternalAreaCard
                    "
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <StandIcon class="mr-1" />
                    {{ unitDialogData.data.externalArea }}m&sup2;
                    <span class="tooltip -top-10 left-0">Stand Size</span>
                  </span>

                  <span
                    v-if="unitDialogData.data.isAircon"
                    class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                  >
                    <SnowflakeIcon class="mr-1" />
                    Yes
                    <span class="tooltip -top-10 left-0">Aircon</span>
                  </span>
                </div>
              </div>

              <!-- Unit Financials Content Left Side -->
              <div
                v-if="globals[0].isExpensesSection"
                class="unit-spec-amounts"
              >
                <div>
                  <p
                    v-if="unitDialogData.data.levies"
                    class="mt-2 unit-detail-text"
                  >
                    Levies:
                    <span class="font-semibold text-secondary">
                      {{
                        prettyCurrency(unitDialogData.data.levies.toFixed(0))
                      }}
                      <sup>pm</sup>
                    </span>
                  </p>

                  <p
                    v-if="unitDialogData.data.rates"
                    class="mt-2 unit-detail-text"
                  >
                    Rates:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(unitDialogData.data.rates.toFixed(0)) }}
                      <sup>pm</sup>
                    </span>
                  </p>

                  <div v-if="globals[0].isEstBondCost">
                    <p class="mt-2 unit-detail-text">
                      Est. Bond Cost:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(getEstBondCost()) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>

                  <div
                    v-if="
                      globals[0].isExpense1 && unitDialogData.data.expense1 > 0
                    "
                  >
                    <p class="mt-2 unit-detail-text">
                      {{ globals[0].dynamicExpense1 }}:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(unitDialogData.data.expense1) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>

                  <div
                    v-if="
                      globals[0].isExpense2 && unitDialogData.data.expense2 > 0
                    "
                  >
                    <p class="mt-2 unit-detail-text">
                      {{ globals[0].dynamicExpense2 }}:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(unitDialogData.data.expense2) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>

                  <div
                    v-if="
                      globals[0].isExpense3 && unitDialogData.data.expense3 > 0
                    "
                  >
                    <p class="mt-2 unit-detail-text">
                      {{ globals[0].dynamicExpense3 }}:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(unitDialogData.data.expense3) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>

                  <div v-if="globals[0].isTotalMonthlyCost">
                    <p class="mt-2 unit-detail-text">
                      Total Monthly Cost:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(getTotalMonthlyCost()) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                </div>
                <!-- Unit Financials Content Right Side -->
                <div>
                  <p
                    v-if="
                      unitDialogData.data.unitRentalGuaranteed &&
                      unitDialogData.data.isGuaranteedRental
                    "
                    class="mt-2 unit-detail-text"
                  >
                    <span class="font-semibold rental-guaranteed">
                      {{ unitDialogData.data.unitRentalGuaranteed }} Rental
                      Guarantee
                    </span>
                  </p>

                  <div v-if="globals[0].isEstRental">
                    <p class="mt-2 unit-detail-text">
                      Est. Rental:
                      <span class="font-semibold text-secondary">
                        {{ prettyCurrency(getEstRental()) }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>

                  <div
                    v-if="
                      globals[0].isNetReturn &&
                      !unitDialogData.data.isPlotSize &&
                      (globals[0].layoutStyle[0] === 'floor' ||
                        globals[0].layoutStyle[1] === 'floor')
                    "
                  >
                    <div>
                      <p class="mt-2 unit-detail-text">
                        Net Return:
                        <span class="font-semibold text-secondary">
                          {{ getNetReturn() }}
                          <sup>%</sup>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="
                      globals[0].isMonthlyShortfallOrSurplus &&
                      !unitDialogData.data.isPlotSize &&
                      (globals[0].layoutStyle[0] === 'floor' ||
                        globals[0].layoutStyle[1] === 'floor')
                    "
                  >
                    <div>
                      <p class="mt-2 unit-detail-text">
                        {{
                          Math.sign(getMonthlyShortFall()) > 0
                            ? "Monthly shortfall:"
                            : "Monthly surplus:"
                        }}
                        <span class="font-semibold text-secondary">
                          {{
                            prettyCurrency(
                              Math.sign(getMonthlyShortFall()) > 0
                                ? getMonthlyShortFall()
                                : Math.abs(getMonthlyShortFall()),
                            )
                          }}
                          <sup>pm</sup>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div>
                    <p
                      v-if="globals[0].capitalGrowth"
                      class="mt-2 unit-detail-text"
                    >
                      Capital Growth:
                      <span class="font-semibold text-secondary">
                        {{ globals[0].capitalGrowth }}
                        <sup>%</sup>
                      </span>
                    </p>
                  </div>

                  <div
                    v-if="
                      globals[0].isTotalAnnualROI &&
                      !unitDialogData.data.isPlotSize &&
                      (globals[0].layoutStyle[0] === 'floor' ||
                        globals[0].layoutStyle[1] === 'floor')
                    "
                  >
                    <div>
                      <p class="mt-2 unit-detail-text">
                        Total Annual ROI:
                        <span class="font-semibold text-secondary">
                          <span
                            v-if="
                              globals[0].capitalGrowth &&
                              unitDialogData.data.levies &&
                              unitDialogData.data.rates &&
                              unitDialogData.data.guaranteedRental
                            "
                          >
                            {{
                              (
                                globals[0].capitalGrowth +
                                getNetReturn(globals[0])
                              ).toFixed(2)
                            }}
                          </span>
                          <sup>%</sup>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- action buttons -->
              <div
                class="flex flex-col mt-5"
                v-if="globals[0].isGlobalOverrideAction === true"
              >
                <a :href="globals[0].globalOverrideAction" target="_blank">
                  <button
                    class="w-full mb-3 bg-primary text-primary-inverse hover:bg-primary-600 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                  >
                    {{ globals[0].globalOverrideActionButton }}
                  </button>
                </a>
              </div>

              <div v-else>
                <div class="flex flex-col mt-5">
                  <div v-if="unitDialogData.data.isOverrideAction === true">
                    <a
                      :href="unitDialogData.data.unitOverrideAction"
                      target="_blank"
                    >
                      <button
                        class="w-full mb-3 bg-primary text-primary-inverse hover:bg-primary-600 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                      >
                        {{ unitDialogData.data.unitOverrideActionButton }}
                      </button>
                    </a>
                  </div>

                  <div class="has-tooltip" v-else-if="globals[0].allowReserve">
                    <button
                      disabled
                      class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                      v-if="
                        unitDialogData.data.status === 'pending' ||
                        unitDialogData.data.status === 'pendingUnitDetails' ||
                        unitDialogData.data.status === 'pendingPayment'
                      "
                    >
                      Pending
                    </button>

                    <button
                      disabled
                      class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                      v-if="unitDialogData.data.status === 'reserved'"
                    >
                      Reserved
                    </button>

                    <button
                      disabled
                      class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                      v-if="unitDialogData.data.status === 'sold'"
                    >
                      Sold
                    </button>

                    <div>
                      <button
                        class="relative bg-surface-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                        v-if="
                          unitDialogData.data.status === 'available' &&
                          disableReserve
                        "
                      >
                        <span class="text-md font-semibold uppercase">
                          Reserve
                        </span>
                        <span
                          class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0"
                        >
                          This reserve button will become active on launch</span
                        >
                      </button>

                      <button
                        :disabled="isLoading"
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none border-solid border-green-500 focus:outline-none bg-primary hover:bg-primary-600"
                        @click="
                          unitReserve(
                            unitDialogData.data.name,
                            unitDialogData.data.id,
                          )
                        "
                        v-if="
                          unitDialogData.data.status === 'available' &&
                          !disableReserve &&
                          !isPendingBlocked
                        "
                      >
                        <span
                          v-if="
                            isLoading &&
                            unitDialogData.data.id === unitIdOnClick
                          "
                        >
                          <clipLoader :loading="isLoading" />
                        </span>
                        <span v-else>Reserve</span>
                      </button>

                      <!--                  Max Reservable Units Button-->
                      <button
                        class="relative bg-surface-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                        v-if="
                          unitDialogData.data.status === 'available' &&
                          !disableReserve &&
                          isPendingBlocked
                        "
                      >
                        <span class="text-md font-semibold uppercase">
                          {{ globals[0].maxReservableUnitsHomeScreenButtons }}
                        </span>
                        <span
                          class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0"
                        >
                          {{ globals[0].maxReservableUnitsHomeScreenTooltip }}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="w-1/2 pr-2">
                  <button
                    @click.prevent="
                      openContactDialog(
                        unitDialogData.data.name,
                        unitDialogData.data.standNumber,
                      )
                    "
                    class="flex items-center justify-center w-full mb-3 px-6 py-2 font-bold tracking-wider uppercase text-sm rounded outline-none text-white bg-surface hover:bg-surface-600 border border-solid hover:text-white focus:outline-none"
                  >
                    <ContactIcon :size="23" class="mr-1" />
                    ENQUIRE
                  </button>
                </div>

                <div class="w-1/2 pl-2">
                  <AddToShortlist
                    :unitID="unitDialogData.data.id"
                    :unitName="unitDialogData.data.name"
                    :unit="unitDialogData.data"
                    :show="true"
                    :showTooltip="true"
                    :homepageView="false"
                    :shortListCount="unitDialogData.data.shortlistIncrement"
                  />
                </div>
              </div>
              <!-- Share -->
              <ShareListing :unitName="unitDialogData.data.name" />

              <!-- Disclaimer -->

              <div class="mt-4 text-xs text-center">
                <BaseEditorConverter
                  id="text"
                  class="text-xs py-1 text-left"
                  :settings="globals[0]"
                  :value="globals[0].pleaseNoteMessage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APP_TITLE } from "@/constants.js";

import AddToShortlist from "./AddToShortlist.vue";
import UnitPrice from "../shared/UnitPrice.vue";

import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BalconyIcon from "vue-material-design-icons/Balcony.vue";
import CoverPatioIcon from "vue-material-design-icons/GarageOpenVariant.vue";
import FlowerOutlineIcon from "vue-material-design-icons/FlowerOutline.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import StandIcon from "vue-material-design-icons/ArrowExpandAll.vue";
import HomeIcon from "vue-material-design-icons/Home.vue";
import GarageIcon from "vue-material-design-icons/GarageVariant.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";

import ContactIcon from "vue-material-design-icons/FaceAgent.vue";

import { unitsCollection } from "@/firebaseCompat.js";
import firebase from "firebase/compat/app";
import clipLoader from "../shared/ClipLoader.vue";
import StoreIcon from "vue-material-design-icons/Store.vue";
import BaseEditorConverter from "../shared/BaseEditorConverter.vue";
import { netReturn } from "@/mixins/utils.js";
import MainCarousel from "../shared/MainCarousel.vue";
import ShareListing from "@/components/shared/ShareListing.vue";

export default {
  name: "UnitDialogEstate",
  emits: ["updateUnitDialogData"],
  mixins: [netReturn],
  components: {
    ShareListing,
    BaseEditorConverter,
    StoreIcon,
    AddToShortlist,
    UnitPrice,
    BathroomIcon,
    BalconyIcon,
    CoverPatioIcon,
    FlowerOutlineIcon,
    BedroomIcon,
    TotalIntAreaIcon,
    CarIcon,
    StandIcon,
    HomeIcon,
    GarageIcon,
    TotalAreaIcon,
    ContactIcon,
    SnowflakeIcon,
    clipLoader,
    MainCarousel,
  },
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      type: Array,
      default: () => [],
    },
    unitDialogData: {
      type: Object,
    },
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      unitIdOnClick: "",
      isLoading: false,
      now: new Date(),
      title: APP_TITLE,
      roleStatuses: ["admin", "admin-viewonly", "superadmin"],
    };
  },
  methods: {
    toggleUnitDialogDataOpen() {
      this.$emit("update:unitDialogData", {
        ...this.unitDialogData,
        open: !this.unitDialogData.open,
      });
    },
    cutAfterHyphen(inputString) {
      if (inputString.includes("-")) {
        var parts = inputString.split("-");
        return parts.length > 1 ? parts[1] : inputString;
      } else {
        return inputString;
      }
    },
    getEstBondCost: function () {
      this.setMixinData(this.unitDialogData.data);
      this.setMixinUser(this.user);
      return this.mixinEstBondCost();
    },
    getTotalMonthlyCost: function () {
      this.setMixinData(this.unitDialogData.data);
      this.setMixinUser(this.user);
      return this.mixinTotalMonthlyCost();
    },
    getEstRental: function () {
      this.setMixinData(this.unitDialogData.data);
      return this.mixinEstRental();
    },
    getNetReturn: function () {
      this.setMixinData(this.unitDialogData.data);
      this.setMixinUser(this.user);
      return this.mixinNetReturn();
    },
    getMonthlyShortFall: function () {
      this.setMixinData(this.unitDialogData.data);
      this.setMixinUser(this.user);
      return this.mixinMonthlyShortFall();
    },
    updateNow() {
      this.now = Date.now();
    },
    PMT(ir, np, pv, fv, type) {
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt.toFixed(0);
    },
    toggleModal: function () {
      this.localUnitDialogDataOpen = !this.unitDialogData.open;
      const el = document.body;
      el.style.overflow = "auto";
    },
    closeModal() {
      this.emitter.emit("updateUnitDialogData", { open: false });
    },
    unitReserve(unitName, unitId) {
      this.unitIdOnClick = unitId;
      this.isLoading = true;

      unitsCollection
        .doc(unitId)
        .update({
          pendingCountDownTimerStart: firebase.firestore.Timestamp.now(),
          pendingCountDownTimerExpires: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.toggleModal();
          this.$router.push({
            name: "reserve",
            params: { name: unitName },
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    openContactDialog(unitName, unitErf) {
      this.emitter.emit("update:unit-contact-data", {
        open: true,
        unitName,
        unitErf,
      });
      this.toggleModal();
      this.toggleUnitDialogDataOpen();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
      isPendingBlocked: "isPendingBlocked",
    }),
    disableReserve: function () {
      if (this.msTillLaunchTime >= 0) {
        if (this.unitDialogData.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.globals[0].bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style lang="css" scoped>
.zIdex {
  z-index: 99999 !important;
}

.lb-dialog-close-btn-x {
  z-index: 999999 !important;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.slick-slider {
  touch-action: auto !important;
}

.guaranteed-rental-container {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 600;
  padding: 0.25rem;
  border-radius: 0.25rem;
  z-index: 999999 !important;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.lightweight {
  font-weight: 400;
}

.rental-guaranteed {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.unit-detail-text {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.headerCss {
  letter-spacing: 0rem;
}

.unit-spec-amounts {
  display: flex;
  border-top-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  margin-top: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
}

.sentenceCaseCss {
  text-transform: none !important;
}

@media only screen and (max-width: 425px) {
  .css-zindex {
    z-index: 38;
  }

  .unit-spec-amounts {
    display: flex;
    border-top-width: 1px;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
    margin-top: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
  }
}
</style>
