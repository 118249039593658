<script setup>
import { useInteractiveMode } from "@/composables";
import { toRefs, ref, onMounted, onBeforeUnmount } from "vue";
import InteractiveImage from "@/components/shared/InteractiveImage.vue";

const { toggleInteractiveMode } = useInteractiveMode();

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  pins: {
    type: Array,
    required: true,
  },
});
const { src, pins } = toRefs(props);

const emit = defineEmits({
  clickPin: ({ unit }) =>
    typeof unit === "undefined" || typeof unit === "string",
});
const clickPin = ({ unit }) => {
  emit("clickPin", { unit });
};

const visible = ref(false);
const dialog = ref(null);
const show = () => {
  visible.value = true;
  toggleInteractiveMode();

  window.addEventListener("keydown", escape);
};
const maximizeDialog = () => {
  dialog.value.maximized = true;
};
const hide = () => {
  visible.value = false;
  toggleInteractiveMode();

  window.removeEventListener("keydown", escape);
};
const escape = ({ key }) => {
  if (key !== "Escape") return;
  hide();
};

const paddingTop = ref("!pt-[82px]");
const resize = () => {
  if (window.innerWidth <= 1024) {
    paddingTop.value = "!pt-[70px]";
    return;
  }

  paddingTop.value = "!pt-[82px]";
};
let isMobile = false;
const overlayVisible = ref(false);
const toggleOverlay = () => {
  overlayVisible.value = !overlayVisible.value;
};
const plan = ref(null);
onMounted(() => {
  resize();
  window.addEventListener("resize", resize);

  isMobile = window.innerWidth < 640;
  if (isMobile) {
    toggleOverlay();
    return;
  }

  plan.value.addEventListener("mouseover", toggleOverlay);
  plan.value.addEventListener("mouseout", toggleOverlay);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", resize);

  if (isMobile) return;

  plan.value.removeEventListener("mouseover", toggleOverlay);
  plan.value.removeEventListener("mouseout", toggleOverlay);
});
</script>

<template>
  <div
    ref="plan"
    class="border border-surface-200 rounded-md overflow-hidden w-full bg-primary-inverse"
  >
    <div class="relative" @click="show">
      <div
        v-if="!visible"
        class="z-10 absolute t-0 l-0 w-full h-1/3 rounded-t-md pt-2 flex justify-center pointer-events-none"
        :class="overlayVisible ? 'opacity-100' : 'opacity-0'"
      >
        <div class="relative inline-block">
          <span
            class="relative z-10 text-primary-inverse border border-transparent rounded-b-lg text-sm bg-surface-900/50 shadow-md p-3"
          >
            Tap to explore the interactive plan.
          </span>
        </div>
      </div>
      <InteractiveImage
        :src="src"
        :pins="pins"
        :showZoomPercentage="!overlayVisible"
        disableGestures
        @clickPin="clickPin"
      />
      <p-button
        icon="pi pi-arrow-up-right-and-arrow-down-left-from-center"
        class="!absolute bottom-1 right-1"
        @click="show"
      ></p-button>
    </div>
    <p-dialog
      ref="dialog"
      :visible="visible"
      maximizable
      @show="maximizeDialog"
      :showHeader="false"
      :contentClass="`relative !px-0 !pb-0 ${paddingTop}`"
    >
      <InteractiveImage
        :src="src"
        :fitImageHeight="false"
        :pins="pins"
        @clickPin="clickPin"
        @click="hide"
      />
      <p-button
        icon="pi pi-arrow-down-left-and-arrow-up-right-to-center"
        class="!absolute bottom-1 right-1"
        @click="hide"
      ></p-button>
    </p-dialog>
  </div>
</template>
