<template>
  <div>
    <!--Modal-->
    <button
      class="indexed lb-dialog-close-btn-x"
      v-if="showDialog"
      v-on:click="toggleModal()"
    >
      ×
    </button>

    <div
      v-if="showDialog"
      class="fixed indexed top-10 left-1/2 transform -translate-x-1/2 w-full lg:px-0 lg:max-w-3xl overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none"
    >
      <div class="relative w-auto mx-auto bg-white rounded-lg shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <div class="relative flex-auto p-6">
            <div class="flex flex-wrap">
              <div class="w-full">
                <ul class="flex mb-0 list-none flex-row flex-wrap pt-3 pb-4">
                  <!--Tab 1-->
                  <li class="last:mr-0 w-1/2 text-center">
                    <div class="pr-1">
                      <a
                        class="border text-xs font-bold uppercase py-3 rounded block leading-normal"
                        v-on:click="toggleTabs(1)"
                        v-bind:class="{
                          'border-surface text-surface bg-white': openTab !== 1,
                          'border-primary text-white bg-primary': openTab === 1,
                        }"
                      >
                        Message
                      </a>
                    </div>
                  </li>
                  <!--Tab 2-->
                  <li class="last:mr-0 w-1/2 text-center">
                    <div class="pl-1">
                      <a
                        class="border text-xs font-bold uppercase py-3 rounded block leading-normal"
                        v-on:click="toggleTabs(2)"
                        v-bind:class="{
                          'border-surface text-surface bg-white': openTab !== 2,
                          'border-primary text-white bg-primary': openTab === 2,
                        }"
                      >
                        Sales Consultants
                      </a>
                    </div>
                  </li>
                </ul>
                <div
                  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6"
                >
                  <div class="px-4 py-5 flex-auto">
                    <div class="tab-content tab-space">
                      <!--Tab 1 Content-->
                      <div
                        v-bind:class="{
                          hidden: openTab !== 1,
                          block: openTab === 1,
                        }"
                      >
                        <MainNavBarContactButtonMessageTab
                          :unitName="unitName"
                          :unitErf="unitErf"
                        />
                      </div>
                      <!--Tab 2 Content-->
                      <div
                        v-bind:class="{
                          hidden: openTab !== 2,
                          block: openTab === 2,
                        }"
                      >
                        <MainNavBarContactButtonContactsTab />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showDialog"
      @click="showDialog = false"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import MainNavBarContactButtonContactsTab from "./MainNavBarContactButtonContactsTab.vue";
import MainNavBarContactButtonMessageTab from "./MainNavBarContactButtonMessageTab.vue";
import { settingsCollection } from "@/firebaseCompat.js";

export default {
  name: "MainNavBarContactModal",
  components: {
    MainNavBarContactButtonContactsTab,
    MainNavBarContactButtonMessageTab,
  },
  data() {
    return {
      globals: [],
      showDialog: false,
      unitName: null,
      unitErf: null,
      openTab: 1,
    };
  },
  methods: {
    toggleModal: function () {
      this.showDialog = !this.showDialog;
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
  created() {
    // TODO: add unit name in event data
    var vm = this;
    this.emitter.on("update:unit-contact-data", function (e) {
      vm.showDialog = e.open;
      vm.unitName = e.unitName;
      vm.unitErf = e.unitErf;
    });

    settingsCollection.orderBy("globalDiscountAmount").onSnapshot(
      {
        // Listen for document metadata changes
        includeMetadataChanges: true,
      },
      (snap) => {
        let globals = [];

        snap.forEach((doc) => {
          globals.push({
            allowReserve: doc.data().allowReserve,
            allowDiscount: doc.data().allowDiscount,
            displaySplashScreen: doc.data().displaySplashScreen,
            allowGlobalDiscount: doc.data().allowGlobalDiscount || false,
            globalDiscountType: doc.data().globalDiscountType || null,
            globalDiscountAmount: doc.data().globalDiscountAmount || null,
            interestRate: doc.data().interestRate || null,
            splashScreenMessage: doc.data().splashScreenMessage,
            launchDate: doc.data().launchDate
              ? doc.data().launchDate.toDate()
              : null,
            logo: doc.data().logo,
            headerImage: doc.data().headerImage,
            hidePriceOnSold: doc.data().hidePriceOnSold || false,
            allowDeposit: doc.data().allowDeposit || false,
            depositAmount: doc.data().depositAmount || null,
            setOneDayOnlyDiscount: doc.data().setOneDayOnlyDiscount || false,
            isGlobalOverrideAction: doc.data().isGlobalOverrideAction || false,
            globalOverrideAction: doc.data().globalOverrideAction || null,
            globalOverrideActionButton:
              doc.data().globalOverrideActionButton || null,
            showAvailableUnits: doc.data().showAvailableUnits,
            isUsersOnline: doc.data().isUsersOnline,
            allowDiscountNoSurvey: doc.data().allowDiscountNoSurvey,
            isLaunchDate: doc.data().isLaunchDate || false,
            depositType: doc.data().depositType || null,
            bypassAdmin: doc.data().bypassAdmin || false,
            ...doc.data(),
          });
        });
        this.globals = globals;
      },
    );
  },
};
</script>
<style scoped>
.indexed {
  z-index: 150;
}
</style>
