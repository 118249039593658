<template>
  <div>
    <!-- Global Menu Content -->
    <div v-if="windowWidth <= 750" class="global-Menu-Content">
      <span class="relative inline-flex rounded-md shadow-sm text-navbar-text">
        <!-- Mobi -->
        <button
          @click="toggleDropdown"
          type="button"
          class="inline-flex items-center px-2 py-1 text-white text-sm font-semibold uppercase tracking-wider transition ease-in-out duration-150 rounded hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
        >
          <MenuIcon
            v-if="!displayDropdown"
            :size="25"
            class="text-navbar-text"
          />
          <CloseIcon
            v-if="displayDropdown"
            :size="25"
            class="text-navbar-text"
          />
        </button>
      </span>

      <div v-if="user && user.loggedIn">
        <!-- Drop Down Menu Content -->
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul
              v-click-outside="toggleDropdown"
              class="text-center font-semibold"
            >
              <div>
                <li v-on:click="toggleProfileSubMenu" class="mb-1">
                  <ProfileIcon :size="30" />
                  My Profile
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    :class="{
                      'rotate-180': showProfileSubMenu,
                      'rotate-0': !showProfileSubMenu,
                    }"
                    class="w-5 h-full transition-transform duration-200 transform"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </li>
              </div>

              <div v-if="showProfileSubMenu">
                <!-- Global Discount Content -->

                <li
                  v-if="
                    settings.allowDiscount &&
                    user.loggedIn &&
                    !globals[0].allowDiscountNoSurvey
                  "
                >
                  <MainNavBarProfileDropdownDiscount
                    class="p-2"
                    :close-menu="toggleDropdown"
                  />
                </li>

                <!-- Shortlist Content -->
                <li class="mb-1">
                  <MainNavBarProfileDropdownShortlist
                    :msTillLaunchTime="msTillLaunchTime"
                    :close-menu="toggleDropdown"
                    :user="user"
                    :settings="settings"
                    @update:tooltip="updateTooltip($event)"
                    class="p-2"
                  />
                </li>

                <!-- User Profile Content -->
                <li v-on:click="toggleDropdown" class="mb-1">
                  <router-link
                    :msTillLaunchTime="msTillLaunchTime"
                    :to="{ name: 'profile' }"
                    @click="displayDropdown = false"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    Edit User Details
                  </router-link>
                </li>

                <!-- Admin Btn Content -->
                <li
                  v-on:click="toggleDropdown"
                  class="mb-1"
                  v-if="
                    user &&
                    user.loggedIn &&
                    userRoleStatuses.includes(user.profile.role)
                  "
                >
                  <router-link
                    :to="{ name: 'admin' }"
                    @click="displayDropdown = false"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    Admin Dashboard
                  </router-link>
                </li>
              </div>

              <!-- Dynamic Management Menu Content from Admin -->
              <li
                v-on:click="toggleDropdown"
                class="mb-1"
                v-for="item in menuItems"
                :key="item.id"
              >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- Contact Us Content -->
              <li>
                <MainNavBarContactButton />
              </li>

              <!-- Menu Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  @click="signOut"
                >
                  Sign Out</a
                >
              </li>

              <!-- Menu Footer Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="block w-full text-xs text-surface-400 p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul
              v-click-outside="toggleDropdown"
              class="pt-2 pr-4 pb-0 pl-4 text-center font-semibold"
            >
              <!-- Auth Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <router-link
                  class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  :to="{ name: 'login' }"
                >
                  Login / Sign Up
                </router-link>
              </li>

              <!-- Contact Us Content -->
              <li class="mb-1">
                <MainNavBarContactButton class="p-2" />
              </li>

              <!-- Dynamic Management Menu Content from Admin -->
              <li
                v-on:click="toggleDropdown"
                class="mb-1"
                v-for="item in menuItems"
                :key="item.id"
              >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="block w-full p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- Menu Footer Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="block w-full text-xs text-surface-400 p-2 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settingsCollection, downloadsCollection } from "@/firebaseCompat.js";
import { mapGetters } from "vuex";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

import MainNavBarProfileDropdownDiscount from "./MainNavBarProfileDropdownDiscount.vue";
import MainNavBarProfileDropdownShortlist from "./MainNavBarProfileDropdownShortlist.vue";
import MainNavBarContactButton from "./MainNavBarContactButton.vue";
import { APP_TITLE } from "@/constants.js";
import ProfileIcon from "vue-material-design-icons/AccountCircle.vue";
import { mapState } from "pinia";
import { useUserStore } from "@/state/index.js";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "MainNavBarDownloadsDropdown",
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MenuIcon,
    CloseIcon,
    MainNavBarProfileDropdownDiscount,
    MainNavBarProfileDropdownShortlist,
    MainNavBarContactButton,
    ProfileIcon,
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    showProfileSubMenu: true,
    title: APP_TITLE,
    windowWidth: window.innerWidth,
    displayDropdown: false,
    componentLoading: false,
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
      hidePriceOnSold: false,
      setOneDayOnlyDiscount: false,
      allowGlobalDiscount: false,
      globalDiscountAmount: null,
      globalDiscountType: "",

      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: null,

      showAvailableUnits: "",

      isUsersOnline: false,
      allowDiscountNoSurvey: false,

      isLaunchDate: false,
      interestRate: null,
      logo: null,
      headerImage: null,
      allowDeposit: false,
      depositType: null,
      depositAmount: null,
    },
    menuItems: [],
    userRoleStatuses: [
      "admin",
      "superadmin",
      "agent-principal",
      "agent",
      "admin-viewonly",
    ],
  }),
  computed: {
    ...mapState(useUserStore, ["logOut"]),
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
  },
  methods: {
    showDownloadMenuItems(item) {
      let result = false;
      if (item.filePath && (!item.url || (item.url && item.url === ""))) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    showLinkMenuItems(item) {
      let result = false;
      if ((item.showupload && item.url) || (!item.showupload && item.url)) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    loadMenuItems() {
      downloadsCollection
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.menuItems.push({
              id: doc.id,
              title: doc.data().title,
              order: doc.data().order,
              active: doc.data().active,
              created: doc.data().created,
              modified: doc.data().modified,
              filePath: doc.data().filePath,
              url: doc.data().url,
              showinnewtab: doc.data().showinnewtab,
              showupload: doc.data().showupload,
              loggedinonly: doc.data().loggedinonly || false,
            });
          });
        });
    },
    toggleProfileSubMenu: function () {
      this.showProfileSubMenu = !this.showProfileSubMenu;
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
      this.showProfileSubMenu = true;
    },
    async signOut() {
      try {
        await this.logOut();
        if (!this.globals[0].dataCollection) return;

        this.$router.replace({
          name: "login",
        });
      } catch ({ message: detail }) {
        this.addToast(SEVERITY.ERROR, { detail });
      }
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (doc) => {
          this.settings.allowReserve = doc.data().allowReserve;
          this.settings.allowDiscount = doc.data().allowDiscount;
          this.settings.displaySplashScreen = doc.data().displaySplashScreen;
          this.settings.splashScreenMessage = doc.data().splashScreenMessage;
          this.settings.launchDate = doc.data().launchDate
            ? doc.data().launchDate.toDate()
            : null;
          this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
          this.settings.setOneDayOnlyDiscount =
            doc.data().setOneDayOnlyDiscount || false;
          this.settings.allowGlobalDiscount =
            doc.data().allowGlobalDiscount || false;
          this.settings.globalDiscountAmount =
            doc.data().globalDiscountAmount || null;
          this.settings.globalDiscountType =
            doc.data().globalDiscountType || null;

          this.settings.isGlobalOverrideAction =
            doc.data().isGlobalOverrideAction || false;
          this.settings.globalOverrideAction =
            doc.data().globalOverrideAction || null;
          this.settings.globalOverrideActionButton =
            doc.data().globalOverrideActionButton || null;

          this.settings.showAvailableUnits = doc.data().showAvailableUnits;

          this.settings.isUsersOnline = doc.data().isUsersOnline;

          this.settings.allowDiscountNoSurvey =
            doc.data().allowDiscountNoSurvey;

          this.settings.isLaunchDate = doc.data().isLaunchDate || false;
          this.settings.interestRate = doc.data().interestRate;
          this.settings.logo = doc.data().logo;
          this.settings.headerImage = doc.data().headerImage;
          this.settings.allowDeposit = doc.data().allowDeposit || false;
          this.settings.depositType = doc.data().depositType || null;
          this.settings.depositAmount = doc.data().depositAmount || null;

          this.settings.bypassAdmin = doc.data().bypassAdmin || false;
          this.settings.dataCollection = doc.data().dataCollection || false;
          this.settings.isMyUnits = doc.data().isMyUnits || false;
          this.settings.layoutStyle = doc.data().layoutStyle || null;
        },
      );
    },
  },
  created() {
    this.getSettings();
    this.loadMenuItems();
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style>
#shortlistColor {
  background-color: var(--color);
}

.menu-dropdown-container {
  position: absolute;
  z-index: 40;
  right: 0;
  margin-top: 0.5rem;
  transform-origin: top right;
}

.menu-dropdown-content {
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 0.375rem;
  width: 16rem;
  overflow-y: auto;
}

.global-Menu-Content {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 425px) {
  .menu-dropdown-container {
    margin: 0.5rem 0.5rem;
    left: -898%;
    width: 997%;
  }

  .menu-dropdown-content {
    width: 100%;
  }
}

@media only screen and (max-width: 417px) {
  .menu-dropdown-container {
    left: -877%;
    width: 976%;
  }
}

@media only screen and (max-width: 408px) {
  .menu-dropdown-container {
    left: -856%;
    width: 955%;
  }
}

@media only screen and (max-width: 398px) {
  .menu-dropdown-container {
    left: -831%;
    width: 930%;
  }
}

@media only screen and (max-width: 389px) {
  .menu-dropdown-container {
    left: -809%;
    width: 908%;
  }
}

@media only screen and (max-width: 381px) {
  .menu-dropdown-container {
    left: -790%;
    width: 889%;
  }
}

@media only screen and (max-width: 370px) {
  .menu-dropdown-container {
    left: -763%;
    width: 861%;
  }
}

@media only screen and (max-width: 360px) {
  .menu-dropdown-container {
    left: -739%;
    width: 838%;
  }
}

@media only screen and (max-width: 350px) {
  .menu-dropdown-container {
    left: -713%;
    width: 810%;
  }
}

@media only screen and (max-width: 340px) {
  .menu-dropdown-container {
    left: -687%;
    width: 787%;
  }
}

@media only screen and (max-width: 330px) {
  .menu-dropdown-container {
    left: -665%;
    width: 763%;
  }
}

@media only screen and (max-width: 320px) {
  .menu-dropdown-container {
    left: -641%;
    width: 740%;
  }
}
</style>
