<template>
  <div class="layout">
    <ul v-for="(chatSettings, i) in globalSocialChatSettings" :key="i">
      <!-- ommited -->
      <SocialChat
        icon
        :class="chatSettings.chatStyle"
        :attendants="globalAttendants"
      >
        <template #header>
          <p style="font-weight: 500">
            Click one of our representatives below to chat on
            {{ chatSettings.headerMessagePlatform }}.
          </p>
        </template>
        <template #button>
          <img
            class="btn-image"
            :src="chatSettings.iconSrc"
            :alt="chatSettings.iconAlt"
            aria-hidden="true"
          />
        </template>
        <template #footer>
          <small v-if="chatSettings.footerDetails === 'Default Footer'">
            Contact us on
            <a
              v-bind:href="'tel:' + chatSettings.e164ContactNumber"
              :title="chatSettings.toolPick"
            >
              {{ chatSettings.e164ContactNumber }}
            </a>
            from {{ chatSettings.hoursFrom }} to {{ chatSettings.hoursTo }}
          </small>
          <small v-if="chatSettings.footerDetails === 'Custom Footer'">
            {{ chatSettings.customFooter }}
          </small>
          <small v-if="chatSettings.footerDetails === 'No Footer'" />
        </template>
      </SocialChat>
    </ul>
  </div>
</template>

<script>
import { SocialChat } from "vue-social-chat";
import { attendantsCollection, settingsCollection } from "@/firebaseCompat.js";

export default {
  name: "WhatsAppPage",
  components: {
    SocialChat,
  },
  data: () => ({
    globalSocialChatSettings: [],
    globalAttendants: [],
  }),
  created() {
    settingsCollection.orderBy("platforms").onSnapshot(
      {
        // Listen for document metadata changes
        includeMetadataChanges: true,
      },
      (snap) => {
        let globalSocialChatSettings = [];

        snap.forEach((doc) => {
          globalSocialChatSettings.push({
            platforms: doc.data().platforms,
            chatStyle: doc.data().chatStyle,
            headerMessagePlatform: doc.data().headerMessagePlatform,
            iconSrc: doc.data().iconSrc,
            iconAlt: doc.data().iconAlt,
            footerDetails: doc.data().footerDetails,
            companyContact: doc.data().companyContact,
            e164ContactNumber: doc.data().e164ContactNumber,
            toolPick: doc.data().toolPick,
            hoursFrom: doc.data().hoursFrom,
            hoursTo: doc.data().hoursTo,
            customFooter: doc.data().customFooter,
          });
        });
        this.globalSocialChatSettings = globalSocialChatSettings;
      },
    );
    attendantsCollection.onSnapshot(
      {
        // Listen for document metadata changes
        includeMetadataChanges: true,
      },
      (snap) => {
        let globalAttendants = [];

        snap.forEach((doc) => {
          let data = doc.data();

          globalAttendants.push({
            app: data.socialApp !== "email" ? data.socialApp : null,
            label: data.titleLabel,
            name: data.fullName,
            number: encodeURIComponent(data.e164ContactNumber),
            username: data.username,
            userAppId: data.userAppId,
            href:
              data.socialApp === "email" ? "mailto:" + data.href : data.href,
            avatar: data.avatar,
          });
        });
        this.globalAttendants = globalAttendants;
      },
    );
  },
};
</script>

<style>
.whatsapp-chat {
  --whatsapp: #46c056;
  --vsc-bg-header: var(--whatsapp);
  --vsc-bg-button: var(--whatsapp);
  --vsc-outline-color: var(--whatsapp);
  --vsc-border-color-bottom-header: #289d37;
}

.telegram-chat {
  --telegram: #039be5;
  --vsc-bg-header: var(--telegram);
  --vsc-bg-button: var(--telegram);
  --vsc-outline-color: var(--telegram);
}

.messenger-chat {
  --messenger: #039be5;
  --vsc-bg-header: var(--messenger);
  --vsc-bg-button: var(--messenger);
  --vsc-outline-color: var(--messenger);
  --vsc-border-color-bottom-header: #0085c7;
}

.wechat-chat {
  --wechat: #00ba59;
  --vsc-bg-header: var(--wechat);
  --vsc-bg-button: var(--wechat);
  --vsc-outline-color: var(--wechat);
}

.twitter-chat {
  --twitter: #55acee;
  --vsc-bg-header: var(--twitter);
  --vsc-bg-button: var(--twitter);
  --vsc-outline-color: var(--twitter);
}

.viber-chat {
  --viber: #6f3faa;
  --vsc-bg-header: var(--viber);
  --vsc-bg-button: var(--viber);
  --vsc-outline-color: var(--viber);
}

.line-chat {
  --line: #22ba4f;
  --vsc-bg-header: var(--line);
  --vsc-bg-button: var(--line);
  --vsc-outline-color: var(--line);
}

.btn-image {
  display: inline-block;
  vertical-align: middle;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
  vertical-align: middle;
}
</style>
