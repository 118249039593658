<template>
  <div id="app" class="antialiased">
    <p-toast class="w-[90vw] sm:w-[25vw]" />
    <p-confirmdialog></p-confirmdialog>
    <ThemeWatcher />
    <Transition>
      <div v-if="isLoading" :style="loaderCss" class="loader">
        <div class="img-container">
          <img alt="loading" :src="loaderImage" class="loading-img" />
        </div>
        <p-progressbar
          mode="indeterminate"
          :style="progressBarCssVars"
          style="height: 3px; width: 100%"
          :pt="{
            root: {
              style: { background: 'var(--progressbar-background)' },
            },
            value: {
              style: { background: 'var(--progressbar-color)' },
            },
          }"
        ></p-progressbar>
      </div>
    </Transition>
    <div v-if="!isLoading" class="bg-white min-h-screen flex flex-col">
      <div
        v-if="isLocalAndProd()"
        class="text-center bg-red-500 text-white font-bold"
      >
        PRODUCTION
      </div>
      <div class="grow">
        <router-view :launchDateTime="settings.launchDate" />
      </div>
      <footer class="text-sm p-2">
        <div class="flex flex-col items-center sm:flex-row sm:justify-between">
          <div>
            &copy; {{ new Date().getFullYear() }} LaunchBase - All Rights
            Reserved
          </div>
          <div>
            <div class="flex">
              <a v-bind:href="urlPrivacyPolicy" target="_blank">
                Privacy Policy
              </a>
              <p class="ml-2">Blueprint Version: {{ version }}</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import packageInfo from "../package.json";
import { settingsCollection } from "./firebaseCompat.js";
import store from "../store.js";
import { h, renderSlot } from "vue";
import { useIdle } from "@vueuse/core";
import {
  ActivityState,
  setUserIdle,
  setUserActive,
  startListeningToPresenceUsers,
  stopListeningToPresenceUsers,
} from "./presence.js";
import { mapGetters } from "vuex";
import ThemeWatcher from "@/ThemeWatcher.vue";
import { firestoreStore } from "@/utils/index.js";

export default {
  components: { ThemeWatcher },
  data: () => ({
    isLoadingVuex: true,
    settings: {
      launchDate: null,
    },
    urlPrivacyPolicy: null,
    version: packageInfo.version,
    idleCheck: useIdle(5 * 60 * 1000), // idle after 5 minutes

    usersActive: null,
    listenerUsersActive: null,

    usersIdle: null,
    listenerUsersIdle: null,

    isPending: null,

    loaderImage: `https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_FIREBASE_PROJECT_ID}.appspot.com/o/assets%2Floader.webp?alt=media`,
  }),
  created() {
    console.log(
      `%c🚀%c %claunchbase%c v${packageInfo.version}`,
      "font-size: 20px;line-height: 60px;",
      "font-size: 5px;",
      "font-size: 20px; color : orange; text-shadow: 1px 1px 1px black;",
      "color : yellow; text-shadow: 1px 1px 1px black;",
    );

    const { pending } = firestoreStore();
    this.isPending = pending;

    // -------- TODO: Remove legacy code below --------
    settingsCollection.doc("globalSettings").onSnapshot(async (doc) => {
      let data = doc.data();

      if (!data) {
        // if data is empty then it's likely because of a firestore error: Could not reach Cloud Firestore backend. Backend didn't respond within 10 seconds.
        // see https://github.com/firebase/firebase-js-sdk/issues/1674#issuecomment-2048556435
        console.log("Could not load settings.");
        return;
      }

      let globals = [];

      globals.push({
        launchDateTimeZone: data.launchDateTimeZone
          ? data.launchDateTimeZone.toDate()
          : null,
        launchDate: data.launchDate ? data.launchDate.toDate() : 0,
        isUsersOnline: data.isUsersOnline ? data.isUsersOnline : false,
        isShareListing: data.isShareListing ? data.isShareListing : true,
        isExpensesSection: data.isExpensesSection
          ? data.isExpensesSection
          : true,
        ...data,
      });

      this.settings.launchDate = data.launchDate ? data.launchDate.toDate() : 0;
      this.urlPrivacyPolicy = data.urlPrivacyPolicy || null;

      await store.dispatch("handleGlobals", globals);

      this.isLoadingVuex = false;
    });
    // -----------------------------------------------

    this.listenerUsersActive = startListeningToPresenceUsers(
      ActivityState.ACTIVE,
      (activeCount) => {
        this.usersActive = activeCount;
        this.updateUserStats();
      },
    );

    this.listenerUsersIdle = startListeningToPresenceUsers(
      ActivityState.IDLE,
      (idleCount) => {
        this.usersIdle = idleCount;
        this.updateUserStats();
      },
    );
  },
  // lifted from https://gist.github.com/yyx990803/9bdff05e5468a60ced06c29c39114c6b#error-handling-with-errorcaptured-hook
  errorCaptured(err, vm, info) {
    console.error(err, info);
  },
  render() {
    if (this.error) {
      return h("pre", { style: { color: "red" } }, this.error);
    }
    return renderSlot(this.$slots, "default");
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isLoading: function () {
      return (
        this.isPending?.account.value ||
        this.isPending?.globalSettings.value ||
        this.isPending?.theme.value ||
        this.isLoadingVuex
      );
    },
    isIdle: function () {
      return this.idleCheck.idle;
    },
    progressBarCssVars() {
      return {
        "--progressbar-background": import.meta.env
          .VITE_LOADER_BACKGROUND_COLOUR,
        "--progressbar-color": import.meta.env.VITE_LOADER_PROGRESS_BAR_COLOUR,
      };
    },
    loaderCss() {
      return {
        "--loader-background": import.meta.env.VITE_LOADER_BACKGROUND_COLOUR,
      };
    },
  },
  methods: {
    isLocalAndProd() {
      // determine if we are running against a production configuration in a local setup
      return (
        (location.hostname === "localhost" ||
          location.hostname === "127.0.0.1") &&
        "whitelabel" === import.meta.env.VITE_SENTRY_PACKAGE
      );
    },
    updateUserStats() {
      store.dispatch("handleOnlineUsers", {
        usersActive: this.usersActive ?? 0,
        usersIdle: this.usersIdle ?? 0,
      });
    },
  },
  watch: {
    isIdle(val) {
      if (this.user?.data && this.user.loggedIn) {
        if (val === true) {
          setUserIdle(this.user?.data.uid);
        } else {
          setUserActive(this.user?.data.uid);
        }
      }
    },
  },
  beforeUnmount() {
    stopListeningToPresenceUsers(this.listenerUsersActive);
    stopListeningToPresenceUsers(this.listenerUsersIdle);
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(-5px);
}

.loading-img {
  max-height: 100px;
  padding: 0 85px;
  object-fit: contain;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.loader {
  background-color: var(--loader-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100dvh;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

textarea {
  font-family: Montserrat, Helvetica, Arial, sans-serif, "Segoe UI Emoji",
    "Apple Color Emoji", "Noto Color Emoji", "Twemoji", "Segoe UI";
}

.container {
  display: flex;
  justify-content: flex-end;
}

/* === Global === */

#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* === APP === */
/* Buttons */
.lb-primary-button {
  @apply px-5 py-2 text-white text-sm tracking-widest font-semibold uppercase bg-primary hover:bg-primary-600 rounded;
}

.lb-warning-button {
  @apply p-2 text-white text-sm tracking-widest font-semibold uppercase bg-red-500 hover:bg-red-400 rounded;
}

.lb-warning-button-disabled {
  @apply p-2 text-white text-sm tracking-widest font-semibold uppercase bg-red-100 rounded cursor-not-allowed;
}

.lb-dialog-close-btn {
  @apply px-6 py-3 font-bold tracking-wider uppercase text-sm rounded outline-none bg-surface-400 border hover:bg-surface-500 focus:outline-none;
}

.lb-submit-btn {
  @apply px-4 py-2 text-white font-light tracking-wider bg-primary rounded;
}

.lb-dialog-close-btn-x {
  @apply fixed z-[9999] top-3 right-5 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none;
}

/* Forms */
.lb-icon-text-icon {
  @apply w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center;
}

.lb-icon-text-label {
  @apply text-xs font-semibold px-1;
}

.lb-text-label {
  @apply text-xs font-semibold;
}

/* Tooltips */
.tooltip {
  visibility: hidden;
  position: absolute;
  @apply w-32 text-center rounded shadow-lg p-1 bg-surface text-white;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

/* Dialog */
.lb-dialog-background {
  @apply opacity-50 fixed inset-0 z-[9999] bg-black;
}

/* Pills */
.lb-icon-pill {
  @apply px-3 py-2 bg-surface-300 rounded-full;
}

/* === ADMIN === */
/* Buttons */
.lb-admin-primary-button-large {
  @apply py-3 px-2 font-semibold text-white text-center bg-primary hover:bg-primary-600 rounded-lg shadow;
}

.lb-admin-surface-button-large {
  @apply py-3 px-2 font-semibold text-white text-center bg-surface hover:bg-surface-600 rounded-lg shadow duration-150;
}

/* Forms */
.lb-admin-form-input {
  @apply block;
  border-radius: 0.25rem !important;
}

.lb-admin-form-input-select {
  @apply block;
  width: 75% !important;
  border-radius: 0.25rem !important;
}

/* Navigation */
.lb-admin-man-nav-section-heading {
  @apply mt-5 text-sm text-white font-semibold tracking-wide uppercase;
}

.lb-admin-main-nav-link {
  @apply flex flex-row items-center;
  margin-top: 3px;
}

.lb-admin-main-nav-link svg {
  @apply p-1 text-white;
}

.lb-admin-main-nav-link p {
  @apply ml-2 text-white font-semibold tracking-wide capitalize;
  font-size: 14px;
}

.lb-admin-main-nav-link.router-link-active:not(.no-highlight) {
  @apply bg-white bg-opacity-50 rounded shadow;
}
</style>
