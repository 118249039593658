import firebase from "firebase/compat/app";
import packageInfo from "../package.json";

const ServerValue = firebase.database.ServerValue;
const presenceRef = firebase.database().ref("presence");

export const ActivityState = Object.freeze({
  ACTIVE: "active",
  OFFLINE: "offline",
  IDLE: "idle",
});

export function handleUserPresence(uid) {
  const userPresenceRef = presenceRef.child(uid);

  const isOnlineForDatabase = {
    status: ActivityState.ACTIVE,
    timestamp_active: ServerValue.TIMESTAMP,
    app_version: packageInfo.version,
  };

  const isOfflineForDatabase = {
    status: ActivityState.OFFLINE,
    timestamp_offline: ServerValue.TIMESTAMP,
    app_version: packageInfo.version,
  };

  firebase
    .database()
    .ref(".info/connected")
    .on("value", function (snapshot) {
      if (snapshot.val() === false) {
        return;
      }

      userPresenceRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          userPresenceRef.update(isOnlineForDatabase);
        });
    });
}

export function startListeningToPresenceUsers(state, callback) {
  return presenceRef
    .orderByChild("status")
    .equalTo(state)
    .on(
      "value",
      (snapshot) => {
        const userStats = snapshot.val();
        callback(userStats ? Object.keys(userStats).length : 0);
      },
      (error) => {
        console.error(error);
      },
    );
}

export function stopListeningToPresenceUsers(listener) {
  if (listener) {
    presenceRef.off("value", listener);
  }
}

export async function setUserActive(uid) {
  try {
    const userPresenceRef = presenceRef.child(uid);
    await userPresenceRef.update({
      status: ActivityState.ACTIVE,
      timestamp_active: ServerValue.TIMESTAMP,
      app_version: packageInfo.version,
    });
  } catch (error) {
    console.error("Failed to update user activity status:", error);
  }
}

export async function setUserIdle(uid) {
  try {
    const userPresenceRef = presenceRef.child(uid);
    await userPresenceRef.update({
      status: ActivityState.IDLE,
      timestamp_idle: ServerValue.TIMESTAMP,
      app_version: packageInfo.version,
    });
  } catch (error) {
    console.error("Failed to update user activity status:", error);
  }
}
