<template>
  <div class="rounded-lg">
    <!--Modal-->
    <button
      class="indexed lb-dialog-close-btn-x"
      v-if="showModale"
      v-on:click="toggleModal()"
    >
      ×
    </button>

    <div
      v-if="showModale"
      class="fixed indexed top-10 left-1/2 transform -translate-x-1/2 w-full lg:px-0 lg:max-w-3xl overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none rounded-lg"
    >
      <div class="bg-surface-100 relative w-auto mx-auto rounded-lg shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <div class="relative flex-auto p-6">
            <div class="flex flex-wrap">
              <div class="w-full mx-6 mt-6">
                <div>
                  <div class="flex justify-center align-center font-bold">
                    <p class="text-3xl uppercase text-center">
                      {{ title }} is sold out!
                    </p>
                  </div>

                  <br />
                  <div
                    class="flex flex-col justify-center align-center text-center"
                  >
                    <p class="text-xl font-semibold">
                      You can still join the wait list and should any
                      {{ unitOrPlot }} become available again, you'll be
                      notified straight away.
                    </p>
                  </div>
                </div>

                <br />

                <form
                  class="relative flex flex-col lg:mx-6 lg:px-6 md:mx-6 md:px-6 sm:px-0 sm:mx-0"
                  @submit.prevent="submitForm"
                >
                  <div class="flex flex-col md:flex-row -mx-3">
                    <div class="w-full md:w-1/2 px-3 mb-5">
                      <label
                        for="contact-first-name"
                        class="text-xs fontContainer px-1"
                        >Name</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <NameIcon class="text-surface-400 text-lg" />
                        </div>
                        <input
                          id="contact-first-name"
                          v-model="form.firstName"
                          type="text"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
                          placeholder="Your name"
                        />
                      </div>
                      <span
                        class="block w-full mt-1 text-xs text-red-600"
                        :class="{
                          '': v.form.firstName.$error,
                          invisible: !v.form.firstName.$error,
                        }"
                      >
                        Required
                      </span>
                    </div>
                    <div class="w-full md:w-1/2 px-3 b-5">
                      <label
                        for="contact-last-name"
                        class="text-xs fontContainer px-1"
                        >Surname</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <NameIcon class="text-surface-400 text-lg" />
                        </div>
                        <input
                          id="contact-last-name"
                          v-model="form.lastName"
                          type="text"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
                          placeholder="Your surname"
                        />
                      </div>
                      <span
                        class="block w-full mt-1 text-xs text-red-600"
                        :class="{
                          '': v.form.lastName.$error,
                          invisible: !v.form.lastName.$error,
                        }"
                      >
                        Required
                      </span>
                    </div>
                  </div>

                  <div class="flex flex-col -mx-3">
                    <div class="w-full px-3 mb-5">
                      <label
                        for="contact-email"
                        class="text-xs fontContainer px-1"
                        >Email</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <EmailIcon class="text-surface-400 text-lg" />
                        </div>
                        <input
                          id="contact-email"
                          v-model="form.email"
                          type="email"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
                          placeholder="Your email address"
                        />
                      </div>
                      <span
                        class="block w-full mt-1 text-xs text-red-600"
                        :class="{
                          '': v.form.email.$error,
                          invisible: !v.form.email.$error,
                        }"
                      >
                        Valid email required
                      </span>
                    </div>

                    <div class="w-full px-3 mb-5">
                      <label
                        for="contact-phone"
                        class="text-xs fontContainer px-1"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        @onUpdate="handleUpdateE164ContactNumber"
                        :e164ContactNumber="form.e164ContactNumber"
                      />
                      <span
                        v-if="v.form.e164ContactNumber.$error"
                        class="block w-full text-xs text-red-600"
                      >
                        Please enter a valid contact number</span
                      >
                    </div>
                  </div>

                  <div class="flex flex-col -mx-3">
                    <div class="w-full px-3 mb-8">
                      <label
                        for="contact-message"
                        class="text-xs fontContainer px-1"
                        >Which {{ unitOrPlot }} or {{ unitOrPlot }} type are you
                        interested in?</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        ></div>
                        <input
                          id="contact-message"
                          v-model="form.message"
                          type="text"
                          class="w-full -ml-10 pr-3 py-2 rounded-lg border-2 border-surface-200 outline-none focus:border-primary"
                          placeholder="Write your preference here"
                        />
                      </div>
                      <span
                        class="block w-full mt-1 text-xs text-red-600"
                        :class="{
                          '': v.form.message.$error,
                          invisible: !v.form.message.$error,
                        }"
                      >
                        At least 1 letter required
                      </span>
                    </div>
                  </div>

                  <div class="flex justify-center">
                    <div class="lg:w-3/4 md:w-full px-3 mb-2">
                      <input
                        :disabled="formError"
                        class="block w-full bg-primary hover:bg-primary focus:bg-primary text-white uppercase rounded-lg px-3 py-3 font-semibold"
                        type="submit"
                        value="Join the wait list"
                      />

                      <p
                        class="absolute top-0 left-0 z-50 flex flex-col justify-center items-center h-full w-full bg-white duration-150"
                        v-if="successMessage"
                      >
                        <MessageSentIcon :size="80" class="text-green-500" />
                        Thank you for contacting us. We will be in touch as soon
                        as a unit available.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModale"
      @click="showModale = false"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import {
  unitsCollection,
  profilesCollection,
  noAvailableCollection,
} from "@/firebaseCompat.js";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import EmailIcon from "vue-material-design-icons/At.vue";
import NameIcon from "vue-material-design-icons/Account.vue";
import MessageSentIcon from "vue-material-design-icons/CheckAll.vue";
import { isNil } from "lodash-es";
import { mapGetters } from "vuex";
import { APP_TITLE } from "@/constants.js";
import { PENDING_STATUSES } from "@/constants/index.js";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import * as Sentry from "@sentry/vue";

export default {
  setup() {
    const v = useVuelidate();
    return { v };
  },
  name: "ModalNoAvailableUnits",
  components: {
    PhoneInput,
    EmailIcon,
    NameIcon,
    MessageSentIcon,
  },
  data() {
    return {
      formError: false,
      title: APP_TITLE,
      PENDING_STATUSES,
      unitsPending: [],
      unitsReserved: [],
      unitsSold: [],
      showModale: true,
      successMessage: false,
      checked: true,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        e164ContactNumber: null,
        message: "",
      },
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      message: { required, minLength: minLength(1) },
      e164ContactNumber: { required },
    },
  },
  methods: {
    handleUpdateE164ContactNumber(e164ContactNumber) {
      this.form.e164ContactNumber = e164ContactNumber;
    },
    getUnitsPending() {
      unitsCollection
        .where("displayOnHomePage", "==", true)
        .where("status", "in", this.PENDING_STATUSES)
        .orderBy("name")
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          async (snap) => {
            let unitsPending = [];

            snap.forEach((doc) => {
              unitsPending.push({
                id: doc.id,
                unitType: doc.data().type,
                shortlistIncrement: doc.data().shortlistIncrement
                  ? doc.data().shortlistIncrement
                  : 0,
                ...doc.data(),
              });
            });
            this.unitsPending = unitsPending;
          },
        );
    },
    getUnitsReserved() {
      unitsCollection
        .where("displayOnHomePage", "==", true)
        .where("status", "==", "reserved")
        .orderBy("name")
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          async (snap) => {
            let unitsReserved = [];

            snap.forEach((doc) => {
              unitsReserved.push({
                id: doc.id,
                unitType: doc.data().type,
                shortlistIncrement: doc.data().shortlistIncrement
                  ? doc.data().shortlistIncrement
                  : 0,
                ...doc.data(),
              });
            });
            this.unitsReserved = unitsReserved;
          },
        );
    },
    getUnitsSold() {
      unitsCollection
        .where("displayOnHomePage", "==", true)
        .where("status", "==", "sold")
        .orderBy("name")
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          async (snap) => {
            let unitsSold = [];

            snap.forEach((doc) => {
              unitsSold.push({
                id: doc.id,
                unitType: doc.data().type,
                shortlistIncrement: doc.data().shortlistIncrement
                  ? doc.data().shortlistIncrement
                  : 0,
                ...doc.data(),
              });
            });
            this.unitsSold = unitsSold;
          },
        );
    },
    toggleModal: function () {
      this.showModale = !this.showModale;
    },
    submitForm() {
      this.v.form.$touch();
      if (this.v.form.$error) {
        return;
      }

      const timeStamp = firebase.firestore.Timestamp.now();

      const messageData = {
        form: "No Available Units",
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        e164ContactNumber: this.form.e164ContactNumber,
        unitName: "No Available Units",
        message: this.form.message,
        timestamp: timeStamp,
      };

      noAvailableCollection.add(messageData).then(() => {
        this.successMessage = true;
      });

      const profileData = {
        emailNoAvailableUnits: true,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update(profileData)
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
  },
  created() {
    this.getUnitsPending();
    this.getUnitsReserved();
    this.getUnitsSold();
  },
  mounted() {
    const user = this.user;

    if (!isNil(user) && user.loggedIn) {
      if (!isNil(user) && !isNil(user.profile)) {
        if (this.user.profile.role !== "agent") {
          this.form.firstName = this.user.profile.firstName;
          this.form.lastName = this.user.profile.lastName;
          this.form.email = this.user.profile.email;
          this.form.e164ContactNumber = this.user.profile.e164ContactNumber;
        }
      } else {
        this.$router.replace({ name: "home" });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
    unitOrPlot: function () {
      if (
        (this.globals[0].layoutStyle[0] === "floor" ||
          this.globals[0].layoutStyle[1] === "floor" ||
          this.globals[0].layoutStyle[2] === "floor") &&
        (this.globals[0].layoutStyle[0] === "plot size" ||
          this.globals[0].layoutStyle[1] === "plot size" ||
          this.globals[0].layoutStyle[2] === "plot size") &&
        (this.globals[0].layoutStyle[0] === "estate" ||
          this.globals[0].layoutStyle[1] === "estate" ||
          this.globals[0].layoutStyle[2] === "estate")
      ) {
        return "unit / plot / home";
      } else if (
        this.globals[0].layoutStyle[0] === "floor" ||
        this.globals[0].layoutStyle[1] === "floor"
      ) {
        return "unit";
      } else if (
        this.globals[0].layoutStyle[0] === "plot size" ||
        this.globals[0].layoutStyle[1] === "plot size"
      ) {
        return "plot";
      } else if (
        this.globals[0].layoutStyle[0] === "estate" ||
        this.globals[0].layoutStyle[1] === "estate"
      ) {
        return "home";
      }
      return "";
    },
  },
};
</script>
<style scoped>
#checkbox {
  color: var(--button-background-color);
}

#checkbox:focus {
  --checkbox-opacity: 0.5;
  outline-color: var(--button-background-color) var(--checkbox-opacity);
  outline-style: outset;
}

#contact-first-name:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-last-name:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-email:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-phone:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

#contact-message:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

.overflow-y-auto::-webkit-scrollbar {
  display: none;
}

.fontContainer {
  font-weight: 600;
  font-size: 16px;
  color: #6b7280;
}

.positionContainer {
  position: fixed;
  top: -0.25rem !important;
  right: -0.25rem !important;
}

.input-tel__label {
  color: var(--boarder-color) !important;
}

.input-tel__input {
  border-width: 2px !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.input-tel.is-valid .input-tel__label[data-v-e59be3b4] {
  color: #424542 !important;
}

.input-tel__input:focus,
.input-tel__input:hover,
::before,
::after,
.country-selector__input:focus,
.country-selector__input:hover,
.input-tel__input:active {
  border-width: 2px;
  --tw-border-opacity: 1 !important;
  --tw-ring-color: var(--boarder-color) var(--tw-border-opacity) !important;
  border-color: var(--boarder-color) !important;
  box-shadow: var(--boarder-color) var(--tw-border-opacity) !important;

  animation: none !important;

  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: #fff !important;
  --tw-ring-color: var(--boarder-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  border-color: var(--boarder-color) !important;
}

.country-selector__list__item.selected {
  background-color: var(--boarder-color) !important;
}

.country-selector__input {
  border-width: 2px !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.country-selector__input:focus {
  border-width: 2px;
  /*outline: 2px solid #2563eb !important;*/
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}

.country-selector__label {
  color: #424542 !important;
}

.phone-container {
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.phone-container:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}

.indexed {
  z-index: 150;
}
</style>
