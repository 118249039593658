<template>
  <div style="display: none"></div>
</template>
<script setup>
import { watch } from "vue";
import tinycolor from "tinycolor2";
import { adjustShade, firestoreStore } from "@/utils";

const {
  state: { theme },
} = firestoreStore();

const tiny2rgb = (tiny) => {
  return `${Math.round(tiny._r)} ${Math.round(tiny._g)} ${Math.round(tiny._b)}`;
};

const setTheme = () => {
  const rootStyle = document.documentElement.style;
  // PRIMARY
  let primaryShades = [
    { property: "--primary-50", adjustment: -45 },
    { property: "--primary-100", adjustment: -40 },
    { property: "--primary-200", adjustment: -30 },
    { property: "--primary-300", adjustment: -20 },
    { property: "--primary-400", adjustment: -10 },
    { property: "--primary-500", adjustment: 0 },
    { property: "--primary-600", adjustment: 10 },
    { property: "--primary-700", adjustment: 20 },
    { property: "--primary-800", adjustment: 30 },
    { property: "--primary-900", adjustment: 40 },
    { property: "--primary-950", adjustment: 45 },
  ];

  primaryShades.forEach((shade) => {
    rootStyle.setProperty(
      shade.property,
      tiny2rgb(adjustShade(theme.value.colours.primary, shade.adjustment)),
    );
  });

  // SURFACE
  let surfaceShades = [
    { property: "--surface-50", adjustment: -45 },
    { property: "--surface-100", adjustment: -40 },
    { property: "--surface-200", adjustment: -30 },
    { property: "--surface-300", adjustment: -20 },
    { property: "--surface-400", adjustment: -10 },
    { property: "--surface-500", adjustment: 0 },
    { property: "--surface-600", adjustment: 10 },
    { property: "--surface-700", adjustment: 20 },
    { property: "--surface-800", adjustment: 30 },
    { property: "--surface-900", adjustment: 40 },
    { property: "--surface-950", adjustment: 45 },
  ];

  surfaceShades.forEach((shade) => {
    rootStyle.setProperty(
      shade.property,
      tiny2rgb(adjustShade(theme.value.colours.surface, shade.adjustment)),
    );
  });

  // SECONDARY
  let secondaryShades = [
    { property: "--secondary-50", adjustment: -45 },
    { property: "--secondary-100", adjustment: -40 },
    { property: "--secondary-200", adjustment: -30 },
    { property: "--secondary-300", adjustment: -20 },
    { property: "--secondary-400", adjustment: -10 },
    { property: "--secondary-500", adjustment: 0 },
    { property: "--secondary-600", adjustment: 10 },
    { property: "--secondary-700", adjustment: 20 },
    { property: "--secondary-800", adjustment: 30 },
    { property: "--secondary-900", adjustment: 40 },
    { property: "--secondary-950", adjustment: 45 },
  ];

  secondaryShades.forEach((shade) => {
    rootStyle.setProperty(
      shade.property,
      tiny2rgb(adjustShade(theme.value.colours.secondary, shade.adjustment)),
    );
  });

  // NAVBAR BACKGROUND
  if (theme.value.colours.navbar.background) {
    rootStyle.setProperty(
      "--navbar-background",
      tiny2rgb(tinycolor(theme.value.colours.navbar.background)),
    );
  } else {
    // set back to default
    rootStyle.setProperty(
      "--navbar-background",
      rootStyle.getPropertyValue("--surface-100"),
    );
  }

  // NAVBAR TEXT
  if (theme.value.colours.navbar.text) {
    rootStyle.setProperty(
      "--navbar-text",
      tiny2rgb(tinycolor(theme.value.colours.navbar.text)),
    );
  } else {
    // set back to default
    rootStyle.setProperty(
      "--navbar-text",
      rootStyle.getPropertyValue("--primary-500"),
    );
  }
};

watch(
  theme,
  (newVal) => {
    if (!newVal) return;
    setTheme();
  },
  { immediate: true },
);
</script>

<style>
/*
As per https://tailwind.primevue.org/vite/#tailwind-config
We're leaving all of these definitions here because primevue is likely using them in their tailwind themes

These will be the default values until such time that they are replaced by the development configured values
 */
:root {
  --primary-50: 255 255 255;
  --primary-100: 255 253 251;
  --primary-200: 251 221 204;
  --primary-300: 247 189 157;
  --primary-400: 243 158 110;
  --primary-500: 239 126 63;
  --primary-600: 232 95 19;
  --primary-700: 185 76 15;
  --primary-800: 138 57 11;
  --primary-900: 90 37 8;
  --primary-950: 67 28 6;
  --surface-0: 255 255 255;
  --surface-50: 250 250 250;
  --surface-100: 244 244 245;
  --surface-200: 228 228 231;
  --surface-300: 212 212 216;
  --surface-400: 161 161 170;
  --surface-500: 113 113 122;
  --surface-600: 82 82 91;
  --surface-700: 63 63 70;
  --surface-800: 39 39 42;
  --surface-900: 24 24 27;
  --surface-950: 9 9 11;
  --secondary-50: 255 255 255;
  --secondary-100: 255 253 251;
  --secondary-200: 251 221 204;
  --secondary-300: 247 189 157;
  --secondary-400: 243 158 110;
  --secondary-500: 113 113 122;
  --secondary-600: 232 95 19;
  --secondary-700: 185 76 15;
  --secondary-800: 138 57 11;
  --secondary-900: 90 37 8;
  --secondary-950: 67 28 6;

  --primary: var(--primary-500);
  --primary-inverse: var(--surface-0);
  --primary-hover: var(--primary-600);
  --primary-active-color: var(--primary-600);

  --primary-highlight-opacity: 0.1;
  --primary-highlight-inverse: var(--primary-700);
  --primary-highlight-hover-opacity: 0.2;

  --surface: var(--surface-500);

  --secondary: var(--secondary-500);

  --navbar-background: var(--surface-100);
  --navbar-text: var(--primary-500);
}

.dark {
  --primary: var(--primary-400);
  --primary-inverse: var(--surface-900);
  --primary-hover: var(--primary-300);
  --primary-active-color: var(--primary-300);

  --primary-highlight-opacity: 0.2;
  --primary-highlight-inverse: var(--surface-0);
  --primary-highlight-hover-opacity: 0.3;
}

.customized-primary {
  &:not(.dark) {
    --primary: var(--primary-950);
    --primary-inverse: var(--surface-0);
    --primary-hover: var(--primary-800);
    --primary-active-color: var(--primary-900);

    --primary-highlight-opacity: 1;
    --primary-highlight-inverse: var(--surface-0);
    --primary-highlight-hover-opacity: 0.8;
  }

  &.dark {
    --primary: var(--primary-50);
    --primary-inverse: var(--surface-950);
    --primary-hover: var(--primary-100);
    --primary-active-color: var(--primary-100);

    --primary-highlight-opacity: 0.1;
    --primary-highlight-inverse: var(--surface-0);
    --primary-highlight-hover-opacity: 0.2;
  }
}
</style>
