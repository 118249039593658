<template>
  <!-- Filter Drop Down Content -->
  <div class="relative w-full">
    <button
      @click="generateShareLink"
      class="flex flex-row w-full py-2 justify-center text-white bg-surface border border-bg-surface rounded focus:outline-none focus:shadow-outline"
    >
      <LinkIcon :size="20" />
    </button>
  </div>
</template>

<script>
import LinkIcon from "vue-material-design-icons/ExportVariant.vue";
import { isEmpty } from "lodash-es";
import { useClipboard } from "@vueuse/core";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "MainUnitReserveListFilterShareLink",
  components: {
    LinkIcon,
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    componentLoading: false,
    shareURL: "",
    addedToClipboard: false,
  }),
  methods: {
    generateShareLink() {
      this.displayDropdown = true;

      let url = window.location.origin + this.$route.path;
      let query = this.$route.query;
      let queryBuilder = "";
      var queryCounter = 0;

      if (query !== null || !isEmpty(query)) {
        // minprice
        if (query.minprice && query.minprice > 0) {
          queryBuilder += `minprice=${query.minprice}&`;
          queryCounter += 1;
        }

        // maxprice
        if (query.maxprice && query.maxprice < 99999999) {
          queryBuilder += `maxprice=${query.maxprice}&`;
          queryCounter += 1;
        }

        // aspect
        if (query.aspect) {
          queryBuilder += `aspect=${query.aspect}&`;
          queryCounter += 1;
        }

        // floor
        if (query.floor) {
          queryBuilder += `floor=${query.floor}&`;
          queryCounter += 1;
        }

        // order
        if (query.order) {
          queryBuilder += `order=${query.order}&`;
          queryCounter += 1;
        }

        // status
        if (query.status) {
          queryBuilder += `status=${query.status}&`;
          queryCounter += 1;
        }

        if (query.unit) {
          queryBuilder += `unit=${query.unit}&`;
          queryCounter += 1;
        }
        if (queryCounter > 0) {
          url += "?";
          url += queryBuilder.slice(0, -1);
        }
      }

      this.shareURL = url;
      useClipboard().copy(this.shareURL);

      this.addToast(SEVERITY.INFO, { detail: "Link copied to clipboard." });
    },
  },
};
</script>
