import { createGlobalState } from "@vueuse/core";
import { ref } from "vue";

export const useInteractiveMode = createGlobalState(() => {
  const active = ref(false);
  const toggleInteractiveMode = () => {
    active.value = !active.value;
  };
  return { active, toggleInteractiveMode };
});
