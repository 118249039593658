import { firestoreStore } from "@/utils/index.js";

const {
  state: { globalSettings },
} = firestoreStore();

export default {
  methods: {
    prettyCurrency(value, showCurrency = true) {
      if (!value) return "";
      const prettyValue = value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      let currency = showCurrency
        ? globalSettings?.value.currencyPrefix || "R"
        : "";

      return `${currency}${prettyValue}`;
    },
  },
};
