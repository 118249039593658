<template>
  <div>
    <!-- *** HomePage View *** -->
    <div v-if="homepageView">
      <button
        v-if="!isUnitInShortlist"
        @click="addToShortlist(unitID)"
        class="styling-button extra-styling-homepageView has-tooltipHomepage justify-center text-sm shadow-border bg-surface-100 hover:bg-surface-600 hover:text-white tracking-wider"
      >
        <span>
          <div class="flex flex-row items-center gap-1">
            <p>ADD TO LIST</p>
            <div id="starIcon">
              <ShortlistIcon />
            </div>
          </div>
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <p class="smallText">
              Shortlisted by
              <span class="is-bold"
                >{{ shortListCount }}
                {{ shortListCount === 1 ? "other" : "others" }}</span
              >
            </p>
          </div>
        </span>
        <span v-if="show && showTooltip" class="tooltip -bottom-7 -left-2"
          >ADD TO LIST</span
        >
      </button>
      <button
        v-else
        @click="deleteFromShortlist(unitID, unitName)"
        class="bg-surface styling-button extra-styling-homepageView has-tooltip justify-center text-sm text-white shadow-border"
      >
        <span v-if="show">
          <div class="flex flex-row items-center gap-1">
            <p>SHORTLISTED</p>
            <div id="starIcon">
              <ShortlistIcon />
            </div>
          </div>
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <p class="smallText">
              Shortlisted by
              <span class="is-bold"
                >{{ shortListCount - 1 }}
                {{ shortListCount - 1 === 1 ? "other" : "others" }}</span
              >
            </p>
          </div>
        </span>
      </button>
    </div>
    <!-- &&& item View &&& -->
    <div v-if="itemView">
      <button
        v-if="!isUnitInShortlist"
        @click="addToShortlist(unitID)"
        class="styling-button extra-styling-itemView has-tooltip justify-center text-sm shadow-border bg-surface-100 hover:bg-surface-600 hover:text-white tracking-wider"
      >
        <span v-if="show">
          <div class="flex flex-row items-center gap-1">
            <p class="mediumTextItemView">ADD TO LIST</p>
            <div id="starIcon">
              <ShortlistIcon />
            </div>
          </div>
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <p class="smallTextItemView">
              Shortlisted by
              <span class="is-bold"
                >{{ shortListCount }}
                {{ shortListCount === 1 ? "other" : "others" }}</span
              >
            </p>
          </div>
        </span>
      </button>
      <button
        v-else
        @click="deleteFromShortlist(unitID, unitName)"
        class="bg-surface styling-button extra-styling-itemView has-tooltip justify-center text-sm text-white shadow-border"
      >
        <span v-if="show">
          <div class="flex flex-row items-center gap-1">
            <p class="mediumTextItemView">SHORTLISTED</p>
            <div id="starIcon">
              <ShortlistIcon />
            </div>
          </div>
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <p class="smallTextItemView">
              Shortlisted by
              <span class="is-bold"
                >{{ shortListCount - 1 }}
                {{ shortListCount - 1 === 1 ? "other" : "others" }}</span
              >
            </p>
          </div>
        </span>
      </button>
    </div>
    <div v-if="cardsLoading && loader">
      <UnitsLoading />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import { profilesCollection, unitsCollection } from "@/firebaseCompat.js";
import { isNil } from "lodash-es";
import { indexOf } from "lodash-es";
import ShortlistIcon from "vue-material-design-icons/Star.vue";
import axios from "axios";
import { NOTIFY_SHORTLIST_URL } from "@/constants.js";
import UnitsLoading from "../app/MainUnitReserveListUnitsLoading.vue";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "AddToShortlist",
  components: {
    ShortlistIcon,
    UnitsLoading,
  },
  props: {
    unit: {
      type: Object,
    },
    unitID: {
      default: null,
      type: String,
    },
    unitName: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    homepageView: {
      type: Boolean,
      default: true,
    },
    itemView: {
      type: Boolean,
      default: true,
    },
    shortListCount: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    cardsLoading: false,
    unitShortlistUser: NOTIFY_SHORTLIST_URL,
  }),
  methods: {
    sendEmail() {
      axios
        .post(this.unitShortlistUser, {
          unit_name: this.unitName,
          unit_erf: this.unit.standNumber,
          first_name: this.user.profile.firstName,
          last_name: this.user.profile.lastName,
          email: this.user.profile.email,
          phone: this.user.profile.e164ContactNumber,
        })
        .then(() => {
          // Do nothing...)
        })
        .catch((error) => {
          console.log(error, "Error sending email");
        });
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    addToShortlist(unitID) {
      if (isNil(this.user) || !this.user.loggedIn) {
        this.$router.push("/login");
        this.addToast(SEVERITY.ERROR, {
          detail:
            "Please create an account and sign in to add a unit to your shortlist",
        });
        return;
      }

      const shortlistIncrementUnit = {
        shortlistIncrement: firebase.firestore.FieldValue.increment(1),
        unitID: unitID,
      };
      const firstDocRefUnit = unitsCollection.doc(unitID);
      const existDocUnit = firstDocRefUnit.get();
      existDocUnit.then((resDoc) => {
        if (resDoc.exists) {
          firstDocRefUnit.update(shortlistIncrementUnit);
        } else {
          firstDocRefUnit.set(shortlistIncrementUnit);
        }
      });

      const shortlistData = {
        unitID: unitID,
        unitName: this.unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayUnion(unitID),
          unitShortlist:
            firebase.firestore.FieldValue.arrayUnion(shortlistData),
        })
        .then(() => {
          this.sendEmail();

          this.addToast(SEVERITY.SUCCESS, {
            detail: "Unit added to your shortlist",
          });
        })
        .catch(({ message: detail }) => {
          this.addToast(SEVERITY.ERROR, { detail });
        });
    },
    deleteFromShortlist(unitID, unitName) {
      this.cardsLoading = true;
      setTimeout(() => {
        let shortlistIncrementUnit = {
          shortlistIncrement: firebase.firestore.FieldValue.increment(-1),
          unitID: unitID,
        };
        unitsCollection.doc(unitID).update(shortlistIncrementUnit);

        const shortlistData = {
          unitID: unitID,
          unitName: unitName,
        };
        profilesCollection
          .doc(this.user.data.uid)
          .update({
            unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
            unitShortlist:
              firebase.firestore.FieldValue.arrayRemove(shortlistData),
          })
          .then(() => {
            this.addToast(SEVERITY.SUCCESS, {
              detail: "Unit removed from your shortlist.",
            });
          })
          .catch(({ message: detail }) => {
            this.addToast(SEVERITY.ERROR, { detail });
          });
        this.cardsLoading = false;
      }, 200);
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
    isUnitInShortlist() {
      if (
        !isNil(this.user) &&
        !isNil(this.user.profile) &&
        !isNil(this.user.profile.unitShortlistIDs)
      ) {
        return indexOf(this.user.profile.unitShortlistIDs, this.unitID) >= 0;
      }
      return false;
    },
  },
};
</script>
<style lang="css" scoped>
#starIcon {
  color: var(--color);
}

.tooltip .tooltip-text {
  visibility: hidden;
  z-index: 9999;
  position: relative;
}

.tooltipHomepage .tooltip-text {
  visibility: hidden;
  z-index: 9999;
  position: relative;
}

.styling-button {
  height: 41px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
}

.extra-styling-itemView {
  width: 100%;
  position: relative;
}

.extra-styling-homepageView {
  width: 165px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.is-bold {
  font-weight: bold;
}

.smallText {
  font-size: 9px;
  font-weight: 400;
}

.smallTextItemView {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 2px;
}

.mediumTextItemView {
  font-size: 12px;
  padding-top: 5px;
  text-align: center;
}

svg {
  display: inline-block;
  vertical-align: middle;
  width: 22px !important;
  height: 22px !important;
  margin-left: 0px;
  margin-top: 0px !important;
}

@media only screen and (max-width: 320px) {
  .smallTextItemView {
    font-size: 7px !important;
  }

  .mediumTextItemView {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 425px) {
  .smallTextItemView {
    font-size: 11px;
  }

  .mediumTextItemView {
    font-size: 13px;
  }

  .svg {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 375px) {
  .smallTextItemView {
    font-size: 9px;
  }

  .mediumTextItemView {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .smallTextItemView {
    font-size: 9px;
  }

  .mediumTextItemView {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1440px) {
  .smallTextItemView {
    font-size: 11px;
  }

  .mediumTextItemView {
    font-size: 12px;
  }
}
</style>
