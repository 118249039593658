<template>
  <div>
    <Carousel ref="unitCarousel" :items-to-show="1" :wrap-around="true">
      <slide v-for="image in slides" :key="image.id">
        <img :src="image?.src || image?.full" alt="Unit Image" />
      </slide>
      <slide v-if="!slides.length">
        <img
          src="../../assets/images/Launchbase_unit_default_image_grey.png"
          alt="Unit Image"
        />
      </slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
    <div class="grid grid-cols-2 m-3">
      <div class="text-right">
        <button
          @click="prev"
          :disabled="slides.length <= 1"
          :class="slides.length <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'"
          class="mr-2 rounded bg-primary px-2 py-1 text-sm font-semibold uppercase text-white hover:bg-primary-600"
        >
          &lt; Prev
        </button>
      </div>
      <div class="text-left">
        <button
          @click="next"
          :disabled="slides.length <= 1"
          :class="slides.length <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'"
          class="rounded bg-primary px-2 py-1 text-sm font-semibold uppercase text-white hover:bg-primary-600"
        >
          Next &gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "MainCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    slides: {
      type: Array,
    },
    global: {
      type: Object,
    },
  },
  methods: {
    next() {
      this.$refs.unitCarousel.next();
    },
    prev() {
      this.$refs.unitCarousel.prev();
    },
  },
};
</script>

<style></style>
