<template>
  <nav v-if="showOnlineUsers" class="navContainer bg-surface-300">
    <div
      v-tooltip="{
        value: `🟢 Users Active: ${onlineUsers.usersActive}\n🟠 Users Idle: ${onlineUsers.usersIdle}`,
        disabled:
          !user.loggedIn || !['superadmin'].includes(user.profile?.role),
      }"
    >
      <template v-if="user.profile?.role === 'superadmin'">
        <router-link :to="{ name: 'admin-profiles' }">
          {{ usersOnline }} {{ usersOnline <= 1 ? "User" : "Users" }} Online
        </router-link>
      </template>
      <template v-else>
        {{ usersOnline }} {{ usersOnline <= 1 ? "User" : "Users" }} Online
      </template>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBarUsersOnline",
  computed: {
    ...mapGetters({
      globals: "globals",
      user: "user",
      onlineUsers: "onlineUsers",
    }),
    usersOnline: function () {
      return this.onlineUsers.usersActive + this.onlineUsers.usersIdle;
    },
    showOnlineUsers: function () {
      return (
        this.globals[0].isUsersOnline &&
        this.usersOnline > (this.globals[0].onlineUserThreshold || 0)
      );
    },
  },
};
</script>

<style scoped>
.navContainer {
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 21;
}
</style>
