import Vuex from "vuex";

export default Vuex.createStore({
  state: {
    globals: [],
    filteredUnits: [],
    images: [],
    units: [],
    onlineUsers: { usersActive: 0, usersIdle: 0 },
    paymentCancel: 0,
    launchDateMilliseconds: 0,
    user: {
      loggedIn: false,
      data: null,
      profile: null,
    },
    isReservationBlocked: false,
    isPendingBlocked: false,
  },
  getters: {
    user(state) {
      return state.user;
    },
    units(state) {
      return state.units;
    },
    filteredUnits(state) {
      return state.filteredUnits;
    },
    globals(state) {
      return state.globals;
    },
    profile: (state) => {
      return state.profile;
    },
    onlineUsers(state) {
      return state.onlineUsers;
    },
    launchDateMilliseconds(state) {
      return state.launchDateMilliseconds;
    },
    images(state) {
      return state.images;
    },
    paymentCancel(state) {
      return state.paymentCancel;
    },
    isReservationBlocked(state) {
      return state.isReservationBlocked;
    },
    isPendingBlocked(state) {
      return state.isPendingBlocked;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_PROFILE(state, data) {
      state.user.profile = data;
    },
    SET_ONLINE_USERS(state, data) {
      state.onlineUsers = data;
    },
    SET_LAUNCH_DATE_MILLISECONDS(state, data) {
      state.launchDateMilliseconds = data;
    },
    SET_UNITS(state, data) {
      state.units = data;
    },
    SET_FILTERED_UNITS(state, data) {
      state.filteredUnits = data;
    },
    SET_GLOBALS(state, data) {
      state.globals = data;
    },
    SET_IMAGES(state, images) {
      state.images = images;
    },
    SET_PAYMENT_CANCEL(state, data) {
      state.paymentCancel = data;
    },
    SET_RESERVATION_BLOCK(state, data) {
      state.isReservationBlocked = data;
    },
    SET_PENDING_BLOCK(state, data) {
      state.isPendingBlocked = data;
    },
  },
  actions: {
    handleOnlineUsers({ commit }, total) {
      commit("SET_ONLINE_USERS", total);
    },
    handleLaunchDateMilliseconds({ commit }, total) {
      commit("SET_LAUNCH_DATE_MILLISECONDS", total);
    },
    handleUnits({ commit }, payload) {
      commit("SET_UNITS", payload);
    },
    handleFilteredUnits({ commit }, payload) {
      commit("SET_FILTERED_UNITS", payload);
    },
    handleGlobals({ commit }, payload) {
      commit("SET_GLOBALS", payload);
    },
    handleImages({ commit }, images) {
      commit("SET_IMAGES", images);
    },
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null && !user.isAnonymous);
      if (user) {
        commit("SET_USER", {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
        });
      }
    },
    setProfile({ commit }, profile) {
      commit("SET_PROFILE", profile);
    },
    handlePaymentCancel({ commit }, total) {
      commit("SET_PAYMENT_CANCEL", total);
    },
    handleReservationBlock({ commit }, payload) {
      commit("SET_RESERVATION_BLOCK", payload);
    },
    handlePendingBlock({ commit }, payload) {
      commit("SET_PENDING_BLOCK", payload);
    },
  },
});
