<template>
  <div>
    <div v-if="agents">
      <div class="flex flex-row flex-wrap">
        <div
          v-for="agent in agents"
          :key="agent.fullName"
          class="w-full md:w-1/2 px-2 py-5"
        >
          <p>{{ agent.fullName }}</p>
          <div class="flex flex-row mt-1 text-sm text-surface-500">
            <MobileIcon class="mr-1" />
            <a :href="`tel:${agent.contactLink}`">{{ agent.contact }}</a>
          </div>
          <div class="flex flex-row mt-1 text-sm text-surface-500">
            <MailIcon class="mr-1" />
            <a :href="`mailto:${agent.email}`">{{ agent.email }}</a>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex flex-col justify-center items-center h-32 w-full">
      <LoadingIcon :size="50" class="text-primary animate-spin" />
    </div>
  </div>
</template>

<script>
import { agentsCollection } from "@/firebaseCompat.js";

import MailIcon from "vue-material-design-icons/Email.vue";
import MobileIcon from "vue-material-design-icons/Cellphone.vue";
import LoadingIcon from "vue-material-design-icons/Loading.vue";

export default {
  name: "MainNavBarContactButtonContactsTab",
  components: {
    MailIcon,
    MobileIcon,
    LoadingIcon,
  },
  data: () => ({
    agents: [],
  }),
  created() {
    agentsCollection
      .where("displayAgent", "==", true)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          this.agents.push({
            id: doc.id,
            fullName: `${doc.data().firstName} ${doc.data().lastName}`,
            email: doc.data().email,
            contact: doc.data().e164ContactNumber,
            contactLink: doc.data().e164ContactNumber,
            order: doc.data().order,
          });
        });
      });

    this.agents = this.agents.sort((a, b) => a.order - b.order);

    console.log(this.agents);
  },
};
</script>
