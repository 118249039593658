<template>
  <div>
    <div v-html="dynamicItem"></div>
  </div>
</template>

<script>
import { APP_TITLE } from "@/constants.js";

export default {
  props: {
    settings: {
      type: Object,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      title: APP_TITLE,
    };
  },
  computed: {
    unitOrPlotOrEstateLowercase: function () {
      if (!this.settings || !this.settings.layoutStyle) {
        return "unit";
      }
      if (
        (this.settings.layoutStyle[0] === "floor" ||
          this.settings.layoutStyle[1] === "floor" ||
          this.settings.layoutStyle[2] === "floor") &&
        (this.settings.layoutStyle[0] === "plot size" ||
          this.settings.layoutStyle[1] === "plot size" ||
          this.settings.layoutStyle[2] === "plot size") &&
        (this.settings.layoutStyle[0] === "estate" ||
          this.settings.layoutStyle[1] === "estate" ||
          this.settings.layoutStyle[2] === "estate")
      ) {
        return "unit / plot / home";
      } else if (
        this.settings.layoutStyle[0] === "floor" ||
        this.settings.layoutStyle[1] === "floor" ||
        this.settings.layoutStyle[2] === "floor"
      ) {
        return "unit";
      } else if (
        this.settings.layoutStyle[0] === "plot size" ||
        this.settings.layoutStyle[1] === "plot size" ||
        this.settings.layoutStyle[2] === "plot size"
      ) {
        return "plot";
      } else if (
        this.settings.layoutStyle[0] === "estate" ||
        this.settings.layoutStyle[1] === "estate" ||
        this.settings.layoutStyle[2] === "estate"
      ) {
        return "home";
      }
      return "unit";
    },
    dynamicItem: function () {
      const title = this.value.replace(/\[Development name]/g, `${this.title}`);
      const depositAmount = title.replace(
        /\[Deposit Amount]/g,
        `${this.settings.depositAmount}`,
      );
      const reservationAgreementHour = depositAmount.replace(
        /\[Reservation Agreement Hour]/g,
        `${this.settings.reservationAgreementHour}`,
      );
      const unitOrPlotOrEstateLowercase = reservationAgreementHour.replace(
        /\[Layout]/g,
        `${this.unitOrPlotOrEstateLowercase}`,
      );
      return unitOrPlotOrEstateLowercase;
    },
  },
};
</script>
