<template>
  <div class="home bg-surface-100">
    <PageBanner v-if="!active" />
    <MainUnitReserveList :msTillLaunchTime="msTillLaunchTime" />
  </div>
</template>

<script>
import PageBanner from "@/components/app/PageBanner.vue";
import MainUnitReserveList from "@/components/app/MainUnitReserveList";
import { useInteractiveMode } from "@/composables";

export default {
  name: "HomeComponent",
  props: {
    msTillLaunchTime: {
      type: Number,
    },
  },
  components: {
    PageBanner,
    MainUnitReserveList,
  },
  setup() {
    const { active } = useInteractiveMode();
    return { active };
  },
};
</script>
