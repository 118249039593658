import firebaseSdkConfig from "../firebaseSdkConfig.js";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import * as Sentry from "@sentry/vue";

const firebaseConfig = firebaseSdkConfig;

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage();

export const functionsInstance = getFunctions(
  firebaseApp,
  import.meta.env.VITE_CF_REGION,
);

// COLLECTION REFERENCES
export const dealsRef = collection(firestore, "deals");
export const historyRef = collection(firestore, "_history");
export const plansRef = collection(firestore, "plans");
export const profilesRef = collection(firestore, "profiles");
export const settingsRef = collection(firestore, "settings");
export const unitsRef = collection(firestore, "units");
export const ctaCardsRef = collection(firestore, "ctaCards");

// FIRESTORE/SENTRY UTILS
function getPath(docRef) {
  return docRef._key.path.segments.join("/");
}

/**
 * Adds a document to Firestore. If an error occurs, it will be captured by Sentry.
 *
 * @param {CollectionReference} collectionRef - A Firestore [CollectionReference](https://firebase.google.com/docs/reference/node/firebase.firestore.CollectionReference).
 * @param {Object} data - The data to add to the collection.
 * @returns {Promise<DocumentReference>} A promise that resolves with the [DocumentReference](https://firebase.google.com/docs/reference/node/firebase.firestore.DocumentReference) of the newly created document.
 */
export async function addDocument(collectionRef, data) {
  try {
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    Sentry.captureMessage(
      `Trying to add a document to ${collectionRef.path}: ${error}`,
    );
    throw error;
  }
}

/**
 * Updates a document in Firestore. If an error occurs, it will be captured by Sentry.
 *
 * @param {DocumentReference} docRef - A Firestore [DocumentReference](https://firebase.google.com/docs/reference/node/firebase.firestore.DocumentReference).
 * @param {Object} data - The data to update the document with.
 * @returns {Promise<void>} A promise that resolves when the document is updated.
 */
export async function updateDocument(docRef, data) {
  try {
    await updateDoc(docRef, data);
  } catch (error) {
    Sentry.captureMessage(`Trying to update ${getPath(docRef)}: ${error}`);
    throw error;
  }
}

/**
 * Deletes a document in Firestore. If an error occurs, it will be captured by Sentry.
 *
 * @param {DocumentReference} docRef - A Firestore [DocumentReference](https://firebase.google.com/docs/reference/node/firebase.firestore.DocumentReference).
 * @returns {Promise<void>} A promise that resolves when the document is deleted.
 */
export async function deleteDocument(docRef) {
  try {
    await deleteDoc(docRef);
  } catch (error) {
    Sentry.captureMessage(`Trying to delete ${getPath(docRef)}: ${error}`);
    throw error;
  }
}
