<template>
  <!-- Aspect === Direction Facing / Direction -->
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="relative flex flex-row px-3 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 overflow-ellipsis hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedViews.length === 0">
        {{ globals[0].outlookName }}
      </span>

      <span
        v-else
        class="flex flex-row overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        <span>
          {{ selectedViews.length }}
          {{
            selectedViews.length === 1
              ? globals[0].outlookName
              : globals[0].outlookName + "s"
          }}
          <SelectedIcon id="svgIcon" :size="20" class="ml-2 text-primary" />
        </span>
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="filter-container-view shadow-lg"
      :class="filterContainerClass"
    >
      <div
        class="pt-8 pb-5 px-5 dropdownContainer w-52 bg-white rounded-md shadow-lg"
      >
        <ul
          class="listContainerPadding max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
        >
          <li v-for="view in viewsList" :key="view" class="mb-2">
            <label class="inline-flex items-center">
              <input
                id="checkboxIcon"
                v-model="selectedViews"
                :value="view"
                @change="setView"
                type="checkbox"
                class="rounded border-surface-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50 cursor-pointer"
              />
              <span class="ml-2">{{ view }}</span>
            </label>
          </li>
        </ul>
        <div class="px-4 pb-4">
          <button
            @click="toggleDropdown"
            class="bg-primary hover:bg-primary-600 p-1 mt-2 w-full border rounded text-white"
          >
            <span> Done </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectedIcon from "vue-material-design-icons/CheckboxMarked.vue";
import { mapGetters } from "vuex";
import { unitsCollection } from "@/firebaseCompat.js";

export default {
  // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
  name: "MainUnitReserveListFilterOutlook",
  props: {
    propViews: {
      default: Array,
    },
  },
  components: {
    SelectedIcon,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    selectedViews: [],
    views: [],
    dropdownRevealedAt: null,
  }),
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getViews() {
      unitsCollection.onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (snap) => {
          let views = [];

          snap.forEach((doc) => {
            views.push({
              view:
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().storeys
                  : doc.data().view,
            });
          });
          this.views = views;
        },
      );
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    setView() {
      const views = this.selectedViews;

      this.$emit("update:view-filter", views);
      this.$router.push({
        query: { ...this.$route.query, view: views },
      });
    },
  },
  created() {
    this.getViews();

    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters({
      units: "units",
      globals: "globals",
    }),
    filterContainerClass() {
      const classList = ["filter-container-view"];
      if (this.windowWidth <= 750) {
        if (this.globals[0].layoutStyle[0] === "estate") {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              classList.push("filter-container-view-left");
            } else {
              classList.push("filter-container-view-right");
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              classList.push("filter-container-view-right");
            } else {
              classList.push("filter-container-view-left");
            }
          }
        } else {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              classList.push("filter-container-view-left");
            } else {
              classList.push("filter-container-view-right");
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              classList.push("filter-container-view-right");
            } else {
              classList.push("filter-container-view-left");
            }
          }
        }
      } else {
        classList.push("filter-container-view-left");
      }
      return classList.join(" ");
    },
    viewsList: function () {
      const uniqueViews = [];
      for (let i = 0; i < this.views.length; i++) {
        if (uniqueViews.indexOf(this.views[i].view) === -1) {
          uniqueViews.push(this.views[i].view);
        }
      }
      const uniqueView = uniqueViews.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      });
      return uniqueView;
    },
  },
  mounted() {
    this.selectedViews = this.propViews;
  },
  watch: {
    propViews: function () {
      this.selectedViews = this.propViews;
    },
  },
};
</script>

<style>
#checkboxIcon {
  color: var(--button-background-color);
}

#checkboxIcon:focus {
  --checkbox-opacity: 0.5;
  outline-color: var(--button-background-color) var(--checkbox-opacity);
  outline-style: outset;
}

#svgIcon {
  color: var(--button-background-color);
}

.filter-container-view {
  position: absolute;
  transform-origin: top right;
  z-index: 20;
}

.filter-container-view-right {
  right: 0 !important;
}

.filter-container-view-left {
  left: 0 !important;
}

.dropdownContainer {
  width: 18rem;
}

.listContainerPadding {
  padding: 17px 17px 0;
}
</style>
