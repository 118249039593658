<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex flex-col md:flex-row w-full">
        <!-- slider -->
        <div class="md:order-2 w-full md:w-2/3 h-auto lg:h-screen bg-white">
          <!-- slider -->
          <div
            class="relative bg-white h-2/3 w-full order-first lg:order-last outline-none"
          >
            <MainCarousel :slides="unit.images" :global="globals[0]" />
          </div>
        </div>

        <!-- sidebar -->
        <div class="w-full md:w-1/3 md:order-1 p-5 bg-surface-200">
          <!-- specs -->
          <div class="w-full mt-1">
            <span
              v-if="unit.isGuaranteedRental"
              class="has-tooltip guaranteed-rental-container"
            >
              GR
              <span class="tooltip left-10 font-normal">
                Guaranteed Rental
              </span>
            </span>
            <p
              class="text-2xl headerCss textContainer font-semibold tracking-widest mt-3"
            >
              {{ title }}
            </p>
            <p class="mt-2 text-xl textContainer font-normal">
              {{
                globals[0].isUnitNameLabel
                  ? globals[0].unitNameLabel !== null
                    ? `${globals[0].unitNameLabel} ${cutAfterHyphen(unit.name)}`
                    : `${cutAfterHyphen(unit.name)}`
                  : `${cutAfterHyphen(unit.name)}`
              }}
            </p>

            <p class="text-xl textContainer font-normal">
              {{ unit.floor }}
            </p>

            <p
              v-if="globals[0].isErfNumber"
              class="mb-2 text-xl textContainer font-normal"
            >
              Erf {{ unit.standNumber }}
            </p>

            <p
              v-if="globals[0].isAspect"
              class="mb-2 text-xl textContainer font-normal"
            >
              {{ unit.aspect }}
            </p>

            <div
              v-if="globals[0].isPrice"
              v-on:click="closeMenuModal"
              @click.prevent="closeMenu"
              class="textContainer"
            >
              <UnitPrice
                :bigPrice="true"
                :normalPrice="false"
                :unit="unit"
                :settings="globals[0]"
                :show="true"
                :tableStyle="false"
                class="textContainer"
              />
            </div>

            <!-- pills -->
            <div class="flex justify-start flex-wrap mt-2">
              <span
                v-if="
                  unit.bedrooms &&
                  unit.bedrooms > 0 &&
                  globals[0].isBedroomsCard
                "
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <BedroomIcon class="mr-1" />
                {{ unit.bedrooms }}
                <span class="tooltip -top-10 left-0">Bedrooms</span>
              </span>

              <span
                v-if="
                  unit.internalAreaEstate &&
                  unit.internalAreaEstate > 0 &&
                  globals[0].isInternalAreaEstate
                "
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <TotalIntAreaIcon />
                {{ unit.internalAreaEstate }}m&sup2;
                <span class="tooltip -top-10 left-0">Internal Area</span>
              </span>

              <span
                v-if="
                  unit.bathrooms &&
                  unit.bathrooms > 0 &&
                  globals[0].isBathroomsCard
                "
                class="iconWeight lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <BathroomIcon class="mr-1" />
                {{ unit.bathrooms }}
                <span class="tooltip -top-10 left-0">Bathrooms</span>
              </span>

              <span
                v-if="unit.balcony && unit.balcony > 0 && globals[0].isBalcony"
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <BalconyIcon />
                {{ unit.balcony }}m&sup2;
                <span class="tooltip -top-10 left-0">Balcony</span>
              </span>

              <div
                v-if="
                  unit.coveredPatio &&
                  unit.coveredPatio > 0 &&
                  globals[0].isCoveredPatio
                "
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <CoverPatioIcon />
                {{ unit.coveredPatio }}m&sup2;
                <span class="tooltip -top-10 left-0">Patio</span>
              </div>

              <span
                v-if="unit.garden && unit.garden > 0 && globals[0].isGarden"
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <FlowerOutlineIcon />
                {{ unit.garden }}m&sup2;
                <span class="tooltip -top-10 left-0">Garden</span>
              </span>

              <span
                v-if="
                  unit.parking && unit.parking > 0 && globals[0].isParkingCard
                "
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <CarIcon class="mr-1" />
                {{ unit.parking }}
                <span class="tooltip -top-10 left-0">Parking Spot</span>
              </span>

              <span
                v-if="unit.garages && unit.garages > 0"
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <GarageIcon class="mr-1" />
                <span class="flex justify-center">
                  {{ unit.garages }}
                  <div v-html="globals[0].isGarageUnit"></div>
                </span>
                <span class="tooltip -top-10 left-0">{{
                  globals[0].isGarageUnit !== ""
                    ? "Garage"
                    : unit.garages === 1
                      ? "Garage"
                      : "Garages"
                }}</span>
              </span>

              <span
                v-if="
                  unit.totalAreaEstate &&
                  unit.totalAreaEstate > 0 &&
                  globals[0].isTotalAreaEstate
                "
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <TotalAreaIcon />
                {{ unit.totalAreaEstate }}m&sup2;
                <span class="tooltip -top-10 left-0">Total Area</span>
              </span>

              <span
                v-if="
                  unit.storeroom && unit.storeroom > 0 && globals[0].isStoreroom
                "
                class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <StoreIcon class="mr-1" />
                {{ unit.storeroom }}
                <span class="tooltip -top-10 left-0">Storeroom</span>
              </span>
              <span
                v-if="
                  unit.internalArea &&
                  unit.internalArea > 0 &&
                  globals[0].isInternalAreaCard
                "
                class="iconWeight lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <HomeIcon class="mr-1" />
                {{ unit.internalArea }}m&sup2;
                <span class="tooltip -top-10 left-0">Home Size</span>
              </span>

              <span
                v-if="
                  unit.externalArea &&
                  unit.externalArea > 0 &&
                  globals[0].isExternalAreaCard
                "
                class="iconWeight lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <StandIcon class="mr-1" />
                {{ unit.externalArea }}m&sup2;
                <span class="tooltip -top-10 left-0">Stand Size</span>
              </span>

              <span
                v-if="unit.isAircon"
                class="iconWeight relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <SnowflakeIcon class="mr-1" />
                Yes
                <span class="tooltip -top-10 left-0">Aircon</span>
              </span>
            </div>

            <div v-if="globals[0].isExpensesSection" class="unit-spec-amounts">
              <div>
                <p
                  v-if="unit.levies"
                  class="mt-2 unit-detail-text textContainer font-normal"
                >
                  Levies:
                  <span class="font-semibold text-secondary">
                    &nbsp;{{ prettyCurrency(unit.levies.toFixed(0)) }}
                    <sup>pm</sup>
                  </span>
                </p>

                <p
                  v-if="unit.rates"
                  class="mt-2 unit-detail-text textContainer font-normal"
                >
                  Rates:
                  <span class="font-semibold text-secondary">
                    &nbsp;{{ prettyCurrency(unit.rates.toFixed(0)) }}
                    <sup>pm</sup>
                  </span>
                </p>

                <div v-if="globals[0].isEstBondCost">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    Est. Bond Cost:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(getEstBondCost()) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div v-if="globals[0].isExpense1 && unit.expense1 > 0">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    {{ globals[0].dynamicExpense1 }}:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(unit.expense1) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div v-if="globals[0].isExpense2 && unit.expense2 > 0">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    {{ globals[0].dynamicExpense2 }}:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(unit.expense2) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div v-if="globals[0].isExpense3 && unit.expense3 > 0">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    {{ globals[0].dynamicExpense3 }}:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(unit.expense3) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div v-if="globals[0].isTotalMonthlyCost">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    Total Monthly Cost:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(getTotalMonthlyCost()) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>
              </div>

              <!-- Unit Financials Content Right Side -->
              <div>
                <p
                  v-if="unit.unitRentalGuaranteed && unit.isGuaranteedRental"
                  class="mt-2 unit-detail-text"
                >
                  <span class="font-semibold rental-guaranteed">
                    {{ unit.unitRentalGuaranteed }} Rental Guarantee
                  </span>
                </p>

                <div v-if="globals[0].isEstRental">
                  <p class="mt-2 unit-detail-text textContainer font-normal">
                    Est. Rental:
                    <span class="font-semibold text-secondary">
                      {{ prettyCurrency(getEstRental()) }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div
                  v-if="
                    globals[0].isNetReturn &&
                    !unit.isPlotSize &&
                    (globals[0].layoutStyle[0] === 'floor' ||
                      globals[0].layoutStyle[1] === 'floor')
                  "
                >
                  <div>
                    <p class="mt-2 unit-detail-text textContainer font-normal">
                      Net Return:
                      <span class="font-semibold text-secondary">
                        {{ getNetReturn() }}
                        <sup>%</sup>
                      </span>
                    </p>
                  </div>
                </div>

                <div
                  v-if="
                    globals[0].isMonthlyShortfallOrSurplus &&
                    !unit.isPlotSize &&
                    (globals[0].layoutStyle[0] === 'floor' ||
                      globals[0].layoutStyle[1] === 'floor')
                  "
                >
                  <div>
                    <p class="mt-2 unit-detail-text textContainer font-normal">
                      {{
                        Math.sign(getMonthlyShortFall()) > 0
                          ? "Monthly shortfall:"
                          : "Monthly surplus:"
                      }}
                      <span class="font-semibold text-secondary">
                        {{
                          prettyCurrency(
                            Math.sign(getMonthlyShortFall()) > 0
                              ? getMonthlyShortFall()
                              : Math.abs(getMonthlyShortFall()),
                          )
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <p
                    v-if="globals[0].capitalGrowth"
                    class="mt-2 unit-detail-text textContainer font-normal"
                  >
                    Capital Growth:
                    <span class="font-semibold text-secondary">
                      {{ globals[0].capitalGrowth }}
                      <sup>%</sup>
                    </span>
                  </p>
                </div>

                <div
                  v-if="
                    globals[0].isTotalAnnualROI &&
                    !unit.isPlotSize &&
                    (globals[0].layoutStyle[0] === 'floor' ||
                      globals[0].layoutStyle[1] === 'floor')
                  "
                >
                  <div>
                    <p class="mt-2 unit-detail-text textContainer font-normal">
                      Total Annual ROI:
                      <span class="font-semibold text-secondary">
                        <span
                          v-if="
                            globals[0].capitalGrowth &&
                            unit.levies &&
                            unit.rates &&
                            unit.guaranteedRental
                          "
                        >
                          {{
                            (
                              globals[0].capitalGrowth +
                              getNetReturn(globals[0])
                            ).toFixed(2)
                          }}
                        </span>
                        <sup>%</sup>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- action buttons -->
          <div class="flex flex-col mt-10">
            <div v-if="globals[0].isGlobalOverrideAction === true">
              <a :href="globals[0].globalOverrideAction" target="_blank">
                <button
                  class="bg-primary hover:bg-primary-600 text-primary-inverse w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                >
                  {{ globals[0].globalOverrideActionButton }}
                </button>
              </a>
            </div>

            <div v-else>
              <div v-if="unit.isOverrideAction === true">
                <a :href="unit.unitOverrideAction" target="_blank">
                  <button
                    class="bg-primary hover:bg-primary-600 text-primary-inverse w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                  >
                    {{ unit.unitOverrideActionButton }}
                  </button>
                </a>
              </div>

              <div class="has-tooltip" v-else-if="globals[0].allowReserve">
                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="
                    unit.status === 'pending' ||
                    unit.status === 'pendingUnitDetails' ||
                    unit.status === 'pendingPayment'
                  "
                >
                  Pending
                </button>

                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'reserved'"
                >
                  Reserved
                </button>

                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none bg-surface-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'sold'"
                >
                  Sold
                </button>

                <div>
                  <button
                    class="relative bg-surface-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                    v-if="unit.status === 'available' && disableReserve"
                  >
                    <span class="text-md font-semibold uppercase">Reserve</span>
                    <span
                      class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0"
                      >This reserve button will become active once sales go
                      live</span
                    >
                  </button>

                  <button
                    class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none bg-primary hover:bg-primary-600"
                    v-else-if="
                      unit.status === 'available' &&
                      !disableReserve &&
                      !isPendingBlocked
                    "
                    @click="unitReserve(unit.name, unit.id)"
                    v-on:click="closeMenuModal"
                    @click.prevent="closeMenu"
                    :disabled="isStartReserving"
                  >
                    <span v-if="isStartReserving && unit.id === unitIdOnClick">
                      <clipLoader :loading="isStartReserving" />
                    </span>
                    <span v-else>Reserve</span>
                  </button>

                  <!--                  Max Reservable Units Button-->
                  <button
                    class="relative bg-surface-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                    v-if="
                      unit.status === 'available' &&
                      !disableReserve &&
                      isPendingBlocked
                    "
                  >
                    <span class="text-md font-semibold uppercase">
                      {{ globals[0].maxReservableUnitsHomeScreenButtons }}
                    </span>
                    <span
                      class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0"
                    >
                      {{ globals[0].maxReservableUnitsHomeScreenTooltip }}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <button
              v-on:click="closeMenuModal"
              @click.once="routerToHome"
              @click.prevent="closeMenu"
              class="w-full mb-3 px-6 py-4 uppercase text-sm text-center font-semibold text-white tracking-wider rounded outline-none bg-surface border border-solid border-surface focus:outline-none"
            >
              View Full Price List
            </button>
          </div>

          <div class="flex flex-row">
            <div class="w-1/2 pr-2">
              <button
                @click.prevent="openContactDialog(unit.name, unit.standNumber)"
                class="flex flex-row items-center justify-center w-full mb-3 px-6 py-2 font-bold tracking-wider uppercase text-sm rounded outline-none text-white bg-surface hover:bg-surface-600 border border-solid hover:text-white focus:outline-none"
              >
                <ContactIcon :size="23" class="mr-1" />
                ENQUIRE
              </button>
            </div>

            <div class="w-1/2 pl-2">
              <AddToShortlist
                :unitID="unit.id"
                :unitName="unit.name"
                :unit="unit"
                :show="true"
                :showTooltip="true"
                :homepageView="false"
              />
            </div>
          </div>

          <!-- Share -->
          <ShareListing :unitName="unit.name" />

          <div class="mt-4 text-xs text-center">
            <BaseEditorConverter
              id="text"
              class="iconWeight text-xs py-1 text-left"
              :settings="globals[0]"
              :value="globals[0].pleaseNoteMessage"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <UnitsLoading />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APP_TITLE } from "@/constants.js";
import AddToShortlist from "../../components/app/AddToShortlist.vue";
import UnitPrice from "../../components/shared/UnitPrice.vue";
import { unitsCollection } from "@/firebaseCompat.js";
import { isNil } from "lodash-es";
import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BalconyIcon from "vue-material-design-icons/Balcony.vue";
import CoverPatioIcon from "vue-material-design-icons/GarageOpenVariant.vue";
import FlowerOutlineIcon from "vue-material-design-icons/FlowerOutline.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import StandIcon from "vue-material-design-icons/ArrowExpandAll.vue";
import HomeIcon from "vue-material-design-icons/Home.vue";
import GarageIcon from "vue-material-design-icons/GarageVariant.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import ContactIcon from "vue-material-design-icons/FaceAgent.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";
import firebase from "firebase/compat/app";
import clipLoader from "../../components/shared/ClipLoader.vue";
import StoreIcon from "vue-material-design-icons/Store.vue";
import BaseEditorConverter from "../../components/shared/BaseEditorConverter.vue";
import { netReturn } from "@/mixins/utils.js";
import MainCarousel from "../../components/shared/MainCarousel.vue";
import UnitsLoading from "@/components/app/MainUnitReserveListUnitsLoading.vue";
import ShareListing from "@/components/shared/ShareListing.vue";

export default {
  name: "UnitComponent",
  mixins: [netReturn],
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    propUnitName: {
      type: String,
    },
    closeMenu: {
      callback: Function,
    },
    closeMenuModal: {
      callback: Function,
    },
  },
  components: {
    ShareListing,
    UnitsLoading,
    BaseEditorConverter,
    StoreIcon,
    AddToShortlist,
    UnitPrice,
    BathroomIcon,
    BalconyIcon,
    CoverPatioIcon,
    FlowerOutlineIcon,
    BedroomIcon,
    TotalIntAreaIcon,
    StandIcon,
    HomeIcon,
    GarageIcon,
    TotalAreaIcon,
    ContactIcon,
    SnowflakeIcon,
    CarIcon,
    clipLoader,
    MainCarousel,
  },
  data: () => ({
    isLoading: true,
    unitIdOnClick: "",
    isStartReserving: false,
    roleStatuses: ["admin", "admin-viewonly", "superadmin"],
    title: APP_TITLE,
    gotResult: false,
    unit: {
      id: null,
      name: null,
      type: null,
      layout: null,
      price: null,
      discountPrice: null,
      levies: null,
      rates: null,
      guaranteedRental: null,
      twentyYearBondInstalment90: null,
      floor: null,
      bedrooms: null,
      bathrooms: null,
      parking: null,
      isAircon: false,
      aspect: null,
      view: null,
      internalArea: null,
      externalArea: null,
      totalArea: null,

      isOneDayOnlyDiscount: false,
      oneDayOnlyDiscount: null,
      oneDayOnlyDiscountDateStart: null,
      oneDayOnlyDiscountDateFinish: null,

      status: null,
    },
    now: new Date(),
  }),
  methods: {
    cutAfterHyphen(inputString) {
      if (inputString.includes("-")) {
        var parts = inputString.split("-");
        return parts.length > 1 ? parts[1] : inputString;
      } else {
        return inputString;
      }
    },
    routerToHome() {
      this.$router.push({ name: "home" });
    },
    getEstBondCost: function () {
      this.setMixinData(this.unit);
      this.setMixinUser(this.user);
      return this.mixinEstBondCost();
    },
    getTotalMonthlyCost: function () {
      this.setMixinData(this.unit);
      this.setMixinUser(this.user);
      return this.mixinTotalMonthlyCost();
    },
    getEstRental: function () {
      this.setMixinData(this.unit);
      return this.mixinEstRental();
    },
    getNetReturn: function () {
      this.setMixinData(this.unit);
      this.setMixinUser(this.user);
      return this.mixinNetReturn();
    },
    getMonthlyShortFall: function () {
      this.setMixinData(this.unit);
      this.setMixinUser(this.user);
      return this.mixinMonthlyShortFall();
    },
    updateNow() {
      this.now = Date.now();
    },
    PMT(ir, np, pv, fv, type) {
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt.toFixed(2);
    },
    unitReserve(unitName, unitId) {
      this.unitIdOnClick = unitId;
      this.isStartReserving = true;

      unitsCollection
        .doc(unitId)
        .update({
          pendingCountDownTimerStart: firebase.firestore.Timestamp.now(),
          pendingCountDownTimerExpires: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.$router.push({
            name: "reserve",
            params: { name: unitName },
          });
          this.showModal = false;
          this.showModalView = false;
        })
        .then(() => {
          this.isStartReserving = false;
        });
    },
    openContactDialog(unitName, unitErf) {
      this.emitter.emit("update:unit-contact-data", {
        open: true,
        unitName,
        unitErf,
      });
    },
    getUnit(unitName) {
      unitsCollection
        .where("name", "==", unitName)
        .limit(1)
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          (snap) => {
            snap.forEach((doc) => {
              this.gotResult = true;

              this.unit.id = doc.id;
              this.unit.name =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().address
                  : doc.data().name;
              this.unit.type = doc.data().type;
              this.unit.layout =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().phase
                  : doc.data().layout;
              this.unit.price = doc.data().price;
              this.unit.purchasePrice = doc.data().purchasePrice;
              this.unit.discountPrice = doc.data().discountPrice;
              this.unit.levies = doc.data().levies;
              this.unit.rates = doc.data().rates;
              this.unit.storeroom = doc.data().storeroom;
              this.unit.guaranteedRental = doc.data().guaranteedRental;
              this.unit.isGuaranteedRental = doc.data().isGuaranteedRental;
              this.unit.unitRentalGuaranteed = doc.data().unitRentalGuaranteed;
              this.unit.isAircon = doc.data().isAircon;
              this.unit.isOverrideAction = doc.data().isOverrideAction;
              this.unit.unitOverrideAction = doc.data().unitOverrideAction;
              this.unit.unitOverrideActionButton =
                doc.data().unitOverrideActionButton;
              this.unit.setGlobalDiscountPrice =
                doc.data().setGlobalDiscountPrice;
              this.unit.twentyYearBondInstalment90 =
                doc.data().twentyYearBondInstalment90;
              this.unit.floor =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().description
                  : doc.data().floor;
              this.unit.bedrooms = doc.data().bedrooms;
              this.unit.bathrooms = doc.data().bathrooms;
              this.unit.parking =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().parkingBays
                  : doc.data().parking;
              this.unit.aspect =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().beds
                  : doc.data().aspect;
              this.unit.view =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().storeys
                  : doc.data().view;
              this.unit.garages =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().garages
                  : 0;
              this.unit.internalArea =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().homeSize
                  : doc.data().internalArea;
              this.unit.externalArea =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().standSize
                  : doc.data().externalArea;
              this.unit.totalArea =
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().flatSizeApartment
                  : doc.data().totalArea;
              this.unit.prearranged = doc.data().prearranged;

              this.unit.isOneDayOnlyDiscount = doc.data().isOneDayOnlyDiscount;
              this.unit.oneDayOnlyDiscount = doc.data().oneDayOnlyDiscount;
              this.unit.oneDayOnlyDiscountDateStart =
                doc.data().oneDayOnlyDiscountDateStart;
              this.unit.oneDayOnlyDiscountDateFinish =
                doc.data().oneDayOnlyDiscountDateFinish;

              this.unit.priceWording = doc.data().priceWording;
              this.unit.isPlotSize = doc.data().isPlotSize;

              this.unit.status = doc.data().status;
              this.unit.images = doc.data().images;
              this.unit = { ...this.unit, ...doc.data() };

              this.isLoading = false;
            });

            if (!this.gotResult) {
              this.$router.push({ name: "not-found" });
            }
          },
        );
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "user",
      launchDateMilliseconds: "launchDateMilliseconds",
      globals: "globals",
      isPendingBlocked: "isPendingBlocked",
    }),
    disableReserve: function () {
      if (this.msTillLaunchTime >= 0) {
        if (this.unit.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.globals[0].bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    let name = this.$route.params.name
      ? this.$route.params.name
      : this.propUnitName;

    if (!isNil(name)) {
      // this is a hack to cater for the fact that we sometimes need to concat address and name with a pipe separator
      if (name.includes("|")) {
        name = name.split("|")[1].trim();
      }
      this.getUnit(name);
    }
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.iconWeight {
  font-weight: normal;
}

.guaranteed-rental-container {
  width: max-content;
  display: block;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 600;
  padding: 0.25rem;
  border-radius: 0.25rem;
  z-index: 999999 !important;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.rental-guaranteed {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.unit-detail-text {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.lightweight {
  font-weight: 400;
}

.headerCss {
  letter-spacing: 0rem;
}

.textContainer {
  text-align: start !important;
  display: flex !important;
}

.unit-spec-amounts {
  display: flex;
  border-top-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  margin-top: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
}

.sentenceCaseCss {
  text-transform: none !important;
}

@media only screen and (max-width: 768px) {
  .unit-spec-amounts {
    flex-direction: column;
  }
}
</style>
