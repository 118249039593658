export const APP_TITLE = import.meta.env.VITE_APP_TITLE;

const CF_URL =
  "https://" +
  import.meta.env.VITE_CF_REGION +
  "-" +
  import.meta.env.VITE_FIREBASE_PROJECT_ID +
  ".cloudfunctions.net";

export const NOTIFY_UNIT_RESERVED_URL = CF_URL + "/notify-unitReserved";
export const NOTIFY_UNIT_PENDING_URL = CF_URL + "/notify-unitPending";
export const NOTIFY_TEST_MAIL_URL = CF_URL + "/notify-testEmail";
export const NOTIFY_SHORTLIST_URL = CF_URL + "/notify-shortlist";
export const NOTIFY_UNIT_UPDATED_URL = CF_URL + "/notify-unitUpdated";
export const NOTIFY_SURVEY_URL = CF_URL + "/notify-surveyEmail";

export const REPORT_PAGE_VIEWS_URL = CF_URL + "/reports-pageViews";
export const REPORT_ALL_USERS_URL = CF_URL + "/reports-allUsers";
export const REPORT_NEW_USERS_URL = CF_URL + "/reports-newUsers";
export const REPORT_AVG_SESSION_DURATION_URL =
  CF_URL + "/reports-avgSessionDuration";

export const GTM = import.meta.env.VITE_GTM_CODE;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const GA_PROPERTY_ID = import.meta.env.VITE_GA_PROPERTY_ID;

export const RENTAL_GUARANTEED_DEFAULT = "1 YR";
export const OVERRIDE_ACTION_BUTTON_DEFAULT = "More Info";
export const GLOBAL_OVERRIDE_ACTION_BUTTON_DEFAULT = "More Info";

export const APP_ADMIN_PAGINATION = 999999999;
export const APP_ONE_DAY_ONLY = import.meta.env.VITE_APP_ONE_DAY_ONLY;

export const REDIRECT_QUERY_KEY = "redirect";
