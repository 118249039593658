<script setup>
import { toRefs } from "vue";
import { usePrimeToast, SEVERITY, hex } from "../../utils";
import { useUserStore } from "../../state";
import { updateDocument, firestore } from "../../firebase";
import { doc, arrayUnion } from "firebase/firestore";
import { useRouter } from "vue-router";

const props = defineProps({
  interactive: {
    type: Boolean,
    default: true,
  },
  ctaCard: {
    type: Object,
    required: true,
    validator: (value, { interactive }) => {
      const { id, header, body, buttonText, buttonLink, colours, dismissible } =
        value;
      const colourKeys = Object.keys(colours);
      let imageExists = true;
      if (interactive) {
        imageExists = value.image?.full && typeof value.image.full === "string";
      }
      return (
        value &&
        typeof id === "string" &&
        typeof header === "string" &&
        typeof body === "string" &&
        typeof buttonText === "string" &&
        typeof buttonLink === "string" &&
        typeof colours === "object" &&
        colourKeys.length === 4 &&
        colourKeys.every((key) => typeof colours[key] === "string") &&
        typeof dismissible === "boolean" &&
        imageExists
      );
    },
  },
});
const { ctaCard } = toRefs(props);

const { uid: userId } = useUserStore();
const addToast = usePrimeToast();
const dismiss = () => {
  if (!props.interactive) return;

  if (userId === undefined) {
    addToast(SEVERITY.INFO, {
      detail: "Please log in to dismiss this banner",
    });
    return;
  }

  try {
    updateDocument(doc(firestore, "profiles", userId), {
      dismissedCTACards: arrayUnion(ctaCard.value.id),
    });
  } catch ({ message: detail }) {
    addToast(SEVERITY.ERROR, { summary: "Failed to dismiss CTA Card", detail });
  }
};

const { push } = useRouter();
const click = () => {
  if (!props.interactive) return;

  const origin = window.location.origin;
  if (ctaCard.value.buttonLink.includes(origin)) {
    const path = ctaCard.value.buttonLink.split(origin)[1];
    push(path);
  } else {
    window.open(ctaCard.value.buttonLink, "_blank");
  }
};
</script>
<template>
  <div
    class="relative rounded-2xl grid grid-cols-1 grid-rows-2 md:max-w-[1216px] md:grid-cols-2 md:grid-rows-1 items-stretch shadow-lg select-none overflow-hidden"
    :style="{ backgroundColor: hex(ctaCard.colours.background) }"
  >
    <p-button
      v-if="ctaCard.dismissible"
      class="!absolute !top-3 !right-3 !w-7 h-7 !border-none !shadow-none !rounded-full bg-white/85 hover:bg-white/100 !p-1 text-xs !text-black"
      icon="pi pi-times"
      aria-label="Dismiss"
      @click="dismiss"
    />
    <div class="order-1 row-span-1 md:order-2 flex flex-col">
      <div
        v-if="!ctaCard.image?.full"
        class="border border-dashed rounded-b-2xl md:rounded-b-none md:rounded-r-2xl h-full flex flex-1 items-center justify-center cursor-pointer"
        :style="{ borderColor: hex(ctaCard.colours.text) }"
      >
        <span :style="{ color: hex(ctaCard.colours.text) }">Image</span>
      </div>
      <img
        v-else
        :src="ctaCard.image.full"
        draggable="false"
        class="h-full object-cover flex-1"
      />
    </div>
    <div
      class="order-2 row-span-1 md:order-1 flex flex-col justify-center p-6 md:p-8"
    >
      <h4 class="mt-0 mb-2" :style="{ color: hex(ctaCard.colours.text) }">
        {{ ctaCard.header || "Header" }}
      </h4>
      <p
        v-if="ctaCard.body"
        class="mb-4"
        :style="{ color: hex(ctaCard.colours.text) }"
      >
        {{ ctaCard.body }}
      </p>

      <p-button
        class="!border-none w-fit"
        :pt="{
          root: {
            style: {
              backgroundColor: hex(ctaCard.colours.backgroundButton),
            },
          },
          label: {
            style: {
              fontWeight: 600,
              color: hex(ctaCard.colours.textButton),
            },
          },
        }"
        :label="ctaCard.buttonText || 'Button Text'"
        @click="click"
      />
    </div>
  </div>
</template>
