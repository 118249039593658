<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="relative flex flex-row px-3 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedFloors.length === 0">
        {{ unitOrPlotOrEstateArea }}
      </span>

      <span
        v-else
        class="flex flex-row overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        <span>
          {{ selectedFloors.length }}
          {{
            selectedFloors.length === 1
              ? unitOrPlotOrEstateArea
              : unitOrPlotOrEstateArea + "'s"
          }}
          <SelectedIcon id="svgIcon" :size="20" class="ml-2 text-primary" />
        </span>
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{
          'rotate-180': displayDropdown,
          'rotate-0': !displayDropdown,
        }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="filter-container-floor shadow-lg"
      :class="filterContainerClass"
    >
      <div
        class="pt-8 pb-5 px-5 dropdownContainer bg-white rounded-md shadow-lg"
      >
        <ul
          class="listContainerPadding max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
        >
          <li v-for="floor in floorList" :key="floor" class="mb-2">
            <label class="inline-flex items-center">
              <input
                id="checkboxIcon"
                v-model="selectedFloors"
                :value="floor"
                @change="setFloor"
                type="checkbox"
                class="rounded border-surface-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50 cursor-pointer"
              />
              <span class="ml-2">{{ floor }}</span>
            </label>
          </li>
        </ul>
        <div class="px-4 pb-4">
          <button
            @click="toggleDropdown"
            class="bg-primary hover:bg-primary-600 p-1 mt-2 w-full border rounded text-white"
          >
            <span> Done </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectedIcon from "vue-material-design-icons/CheckboxMarked.vue";
import { mapGetters } from "vuex";
import { unitsCollection } from "@/firebaseCompat.js";

export default {
  name: "MainUnitReserveListFilterFloor",
  props: {
    propFloors: {
      default: Array,
    },
    isOutlook: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectedIcon,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    selectedFloors: [],
    floors: [],
    dropdownRevealedAt: null,
  }),
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getFloor() {
      unitsCollection.onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (snap) => {
          let floors = [];

          snap.forEach((doc) => {
            floors.push({
              floor:
                this.globals[0].layoutStyle[0] === "estate"
                  ? doc.data().description
                  : doc.data().floor,
            });
          });
          this.floors = floors;
        },
      );
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "desc" ? "asc" : "desc";
      }
      this.currentSort = s;
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    setFloor() {
      const floors = this.selectedFloors;

      this.$emit("update:floor-filter", floors);
      this.$router.push({
        query: { ...this.$route.query, floor: floors },
      });
    },
  },
  created() {
    this.getFloor();

    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
    filterContainerClass() {
      const classList = ["filter-container-floor"];
      if (this.windowWidth <= 750) {
        if (this.globals[0].layoutStyle[0] === "estate") {
          classList.push("");
        } else {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                classList.push("filter-container-layout-right");
              } else {
                classList.push("filter-container-layout-left");
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                classList.push("filter-container-layout-left");
              } else {
                classList.push("filter-container-layout-right");
              }
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                classList.push("filter-container-layout-left");
              } else {
                classList.push("filter-container-layout-right");
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                classList.push("filter-container-layout-right");
              } else {
                classList.push("filter-container-layout-left");
              }
            }
          }
        }
      } else {
        classList.push("filter-container-floor-left");
      }
      return classList.join(" ");
    },
    unitOrPlotOrEstateArea: function () {
      if (
        (this.globals[0].layoutStyle[0] === "floor" ||
          this.globals[0].layoutStyle[1] === "floor" ||
          this.globals[0].layoutStyle[2] === "floor") &&
        (this.globals[0].layoutStyle[0] === "plot size" ||
          this.globals[0].layoutStyle[1] === "plot size" ||
          this.globals[0].layoutStyle[2] === "plot size") &&
        (this.globals[0].layoutStyle[0] === "estate" ||
          this.globals[0].layoutStyle[1] === "estate" ||
          this.globals[0].layoutStyle[2] === "estate")
      ) {
        return "Floor / Size / Description";
      } else if (
        this.globals[0].layoutStyle[0] === "floor" ||
        this.globals[0].layoutStyle[1] === "floor" ||
        this.globals[0].layoutStyle[2] === "floor"
      ) {
        return "Floor";
      } else if (
        this.globals[0].layoutStyle[0] === "plot size" ||
        this.globals[0].layoutStyle[1] === "plot size" ||
        this.globals[0].layoutStyle[2] === "plot size"
      ) {
        return "Size";
      } else if (
        this.globals[0].layoutStyle[0] === "estate" ||
        this.globals[0].layoutStyle[1] === "estate" ||
        this.globals[0].layoutStyle[2] === "estate"
      ) {
        return "Description";
      }
      return "Floor";
    },
    floorList: function () {
      const uniqueFloors = [];
      for (let i = 0; i < this.floors.length; i++) {
        if (uniqueFloors.indexOf(this.floors[i].floor) === -1) {
          uniqueFloors.push(this.floors[i].floor);
        }
      }
      var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      const floors = uniqueFloors.sort(collator.compare);

      // Ensure "Ground" is at the beginning
      const groundIndex = floors.indexOf("Ground");
      if (groundIndex !== -1) {
        floors.splice(groundIndex, 1); // Remove "Ground" from its current position
        floors.unshift("Ground"); // Add "Ground" to the beginning
      }

      return floors;
    },
  },
  mounted() {
    this.selectedFloors = this.propFloors;
  },
  watch: {
    propFloors: {
      handler() {
        this.selectedFloors = this.propFloors;
      },
      deep: true,
    },
  },
};
</script>

<style>
#checkboxIcon {
  color: var(--button-background-color);
}

#checkboxIcon:focus {
  --checkbox-opacity: 0.5;
  outline-color: var(--button-background-color) var(--checkbox-opacity);
  outline-style: outset;
}

#svgIcon {
  color: var(--button-background-color);
}

.filter-container-floor {
  position: absolute;
  transform-origin: top right;
  z-index: 20;
}

.filter-container-floor-right {
  right: 0 !important;
}

.filter-container-floor-left {
  left: 0 !important;
}

.dropdownContainer {
  width: 18rem;
}

.listContainerPadding {
  padding: 0 17px;
}
</style>
