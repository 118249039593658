<template>
  <div class="border-t border-surface-600">
    <div
      v-if="globals[0].isShareListing"
      class="flex flex-row justify-center pt-2 pb-2 border-b border-surface-600"
    >
      <span class="mr-2 uppercase font-normal">Share Listing</span>
      <a
        id="emailHover"
        :href="shareLinks.mail"
        class="mr-2 cursor-pointer hover:text-primary-400"
      >
        <EmailIcon />
      </a>
      <a
        id="linkHover"
        class="mr-2 cursor-pointer hover:text-primary-400"
        @click="handleLinkClipboardCopy"
      >
        <LinkIcon />
      </a>
    </div>
  </div>
</template>

<script>
import { APP_TITLE } from "@/constants.js";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import { mapGetters } from "vuex";
import { useClipboard } from "@vueuse/core";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "ShareListing",
  props: {
    unitName: {
      type: String,
    },
  },
  components: {
    EmailIcon,
    LinkIcon,
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data() {
    return {
      title: APP_TITLE,
    };
  },
  methods: {
    handleLinkClipboardCopy() {
      useClipboard().copy(this.shareLinks.link);
      this.addToast(SEVERITY.INFO, { detail: "Link copied to clipboard." });
    },
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
    shareLinks: function () {
      let url = `${window.location.origin}/unit/${this.unitName}`;

      const title = APP_TITLE;
      const emailSubject = `I found an apartment you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;

      return {
        mail: encodeURI(emailLink),
        link: url,
      };
    },
  },
};
</script>
