<template>
  <div>
    <!-- Global Menu Content -->
    <div v-if="windowWidth > 750" class="global-Menu-Content">
      <span class="relative inline-flex rounded-md shadow-sm">
        <!-- Table & computer -->
        <button
          @click="toggleDropdown"
          type="button"
          class="inline-flex items-center px-4 py-2 text-white text-sm font-semibold uppercase tracking-wider transition ease-in-out duration-150 rounded hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
        >
          <p id="menuText" class="font-bold text-2xl pr-4 text-navbar-text">
            MENU
          </p>
          <div>
            <MenuIcon v-if="!open" :size="30" class="text-navbar-text" />
            <CloseIcon v-if="open" :size="30" class="text-navbar-text" />
          </div>
        </button>
      </span>

      <div v-if="user && user.loggedIn">
        <!-- Drop Down Menu Content -->
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul v-click-away="toggleDropdown" class="text-center font-semibold">
              <!-- Contact Us Content -->
              <li>
                <MainNavBarContactButton class="p-1" />
              </li>

              <!-- Dynamic Management Menu Content from Admin -->
              <li
                v-on:click="toggleDropdown"
                class="mb-1"
                v-for="item in menuItems"
                :key="item.id"
              >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- Menu Footer Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="p-1 block w-full text-xs text-surface-400 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="displayDropdown" class="menu-dropdown-container">
          <div class="menu-dropdown-content bg-surface-100 shadow-lg">
            <ul v-click-away="toggleDropdown" class="text-center font-semibold">
              <!-- Contact Us Content -->
              <li>
                <MainNavBarContactButton />
              </li>

              <!-- Dynamic Management Menu Content from Admin -->
              <li
                v-on:click="toggleDropdown"
                class="mb-1"
                v-for="item in menuItems"
                :key="item.id"
              >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="p-1 block w-full rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- Menu Footer Content -->
              <li v-on:click="toggleDropdown" class="mb-1">
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="block w-full text-xs text-surface-400 rounded hover:text-white hover:bg-surface-600 cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settingsCollection, downloadsCollection } from "@/firebaseCompat.js";
import { mapGetters } from "vuex";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import MainNavBarContactButton from "./MainNavBarContactButton.vue";

export default {
  name: "MainNavBarDownloadsDropdown",
  props: {
    units: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MenuIcon,
    CloseIcon,
    MainNavBarContactButton,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    componentLoading: false,
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
      hidePriceOnSold: false,
      setOneDayOnlyDiscount: false,
      allowGlobalDiscount: false,
      globalDiscountAmount: null,
      globalDiscountType: "",

      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: null,

      showAvailableUnits: "",

      isUsersOnline: false,
      allowDiscountNoSurvey: false,

      isLaunchDate: false,
      interestRate: null,
      logo: null,
      headerImage: null,
      allowDeposit: false,
      depositType: null,
      depositAmount: null,
    },
    open: false,
    menuItems: [],
    userRoleStatuses: [
      "admin",
      "superadmin",
      "agent-principal",
      "agent",
      "admin-viewonly",
    ],
    dropdownRevealedAt: null,
  }),

  methods: {
    showDownloadMenuItems(item) {
      let result = false;
      if (item.filePath && (!item.url || (item.url && item.url === ""))) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    showLinkMenuItems(item) {
      let result = false;
      if ((item.showupload && item.url) || (!item.showupload && item.url)) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    loadMenuItems() {
      downloadsCollection
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.menuItems.push({
              id: doc.id,
              title: doc.data().title,
              order: doc.data().order,
              active: doc.data().active,
              created: doc.data().created,
              modified: doc.data().modified,
              filePath: doc.data().filePath,
              url: doc.data().url,
              showinnewtab: doc.data().showinnewtab,
              showupload: doc.data().showupload,
              loggedinonly: doc.data().loggedinonly || false,
            });
          });
        });
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (doc) => {
          this.settings.allowReserve = doc.data().allowReserve;
          this.settings.allowDiscount = doc.data().allowDiscount;
          this.settings.displaySplashScreen = doc.data().displaySplashScreen;
          this.settings.splashScreenMessage = doc.data().splashScreenMessage;
          this.settings.launchDate = doc.data().launchDate
            ? doc.data().launchDate.toDate()
            : null;
          this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
          this.settings.setOneDayOnlyDiscount =
            doc.data().setOneDayOnlyDiscount || false;
          this.settings.allowGlobalDiscount =
            doc.data().allowGlobalDiscount || false;
          this.settings.globalDiscountAmount =
            doc.data().globalDiscountAmount || null;
          this.settings.globalDiscountType =
            doc.data().globalDiscountType || null;

          this.settings.isGlobalOverrideAction =
            doc.data().isGlobalOverrideAction || false;
          this.settings.globalOverrideAction =
            doc.data().globalOverrideAction || null;
          this.settings.globalOverrideActionButton =
            doc.data().globalOverrideActionButton || null;

          this.settings.showAvailableUnits = doc.data().showAvailableUnits;

          this.settings.isUsersOnline = doc.data().isUsersOnline;

          this.settings.allowDiscountNoSurvey =
            doc.data().allowDiscountNoSurvey;

          this.settings.isLaunchDate = doc.data().isLaunchDate || false;
          this.settings.interestRate = doc.data().interestRate;
          this.settings.logo = doc.data().logo;
          this.settings.headerImage = doc.data().headerImage;
          this.settings.allowDeposit = doc.data().allowDeposit || false;
          this.settings.depositType = doc.data().depositType || null;
          this.settings.depositAmount = doc.data().depositAmount || null;

          this.settings.bypassAdmin = doc.data().bypassAdmin || false;
          this.settings.dataCollection = doc.data().dataCollection || false;
          this.settings.isMyUnits = doc.data().isMyUnits || false;
          this.settings.layoutStyle = doc.data().layoutStyle || null;
          this.settings.isCoveredPatio = doc.data().isCoveredPatio;
        },
      );
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
  },
  created() {
    this.getSettings();
    this.loadMenuItems();
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style>
.menu-dropdown-container {
  position: absolute;
  z-index: 40;
  right: 0;
  margin-top: 0.5rem;
  transform-origin: top right;
}

.menu-dropdown-content {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.375rem;
  width: 16rem;
  overflow-y: auto;
}

.global-Menu-Content {
  position: relative;
  width: 100%;
}
</style>
